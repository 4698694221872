import {
	ApolloClient,
	ApolloLink,
	FetchPolicy,
	from,
	fromPromise,
	InMemoryCache,
	WatchQueryFetchPolicy,
	selectURI,
	NormalizedCacheObject,
	StoreObject
} from '@apollo/client';
import merge from 'deepmerge';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { env, getCookie, hydra } from '../';
import logger from './logger';
import isEqual from 'lodash/isEqual';
import { useRef } from 'react';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const createApolloClient = (options?: {
	fetchPolicy?: {
		watchQuery?: WatchQueryFetchPolicy;
		query?: FetchPolicy;
	};
	batchHttpLinkOptions?: BatchHttpLink.Options;
}): ApolloClient<NormalizedCacheObject> => {
	const { fetchPolicy } = options || { fetchPolicy: {} };
	const uri = env.APOLLO_CLIENT_URI();

	const httpLink = new BatchHttpLink({
		uri,
		credentials: 'include',
		batchKey: (operation) => {
			// default code
			const context = operation.getContext();

			const contextConfig = {
				http: context.http,
				options: context.fetchOptions,
				credentials: context.credentials,
				headers: context.headers
			};

			const defaultKey = selectURI(operation, uri) + JSON.stringify(contextConfig);

			// custom code to make some long queries to run separately all the time
			const { operationName } = operation;
			const operationNameKey = operationName === 'GetAddOnContents' ? operationName : '';

			return defaultKey + operationNameKey;
		},
		...(options?.batchHttpLinkOptions || {})
	});

	const errorLink = onError(({ forward, graphQLErrors, operation, networkError, response }) => {
		if (graphQLErrors) {
			const isAccessDenied = graphQLErrors.some(
				({ message }) => message === 'Access is denied'
			);
			if (isAccessDenied) {
				const refreshToken = getCookie('refresh_token');

				if (!refreshToken) {
					fromPromise(Promise.resolve());
					return;
				}

				fromPromise(
					hydra
						.refreshCookieRequest()
						.then((isRefreshCookieValid) => {
							if (isRefreshCookieValid) {
								forward(operation);
								return Promise.resolve();
							}
						})
						.catch(() => Promise.resolve())
				);
			}
		} else if (networkError) {
			/* eslint-disable no-console */
			console.log('**** Network Error on operation ****', operation);
			console.log('**** Network Error ****', networkError);
			console.log('**** Network Error Name: ' + networkError.name);
			console.log('**** Network Error message: ' + networkError.message);
			networkError.stack && console.log('**** Network Error stack: ' + networkError.stack);
			'cause' in networkError &&
				console.log('**** Network Error cause: ' + networkError.cause);
			if (response?.errors) {
				console.log('**** Response');
				console.log(response);
			}
			/* eslint-enable no-console */
		}
		forward(operation);
	});

	const enableDebugLogsServer = process.env.DEBUG_APOLLO === 'true' ? [logger] : [];

	return new ApolloClient({
		link: from([...enableDebugLogsServer, errorLink, httpLink]),
		cache: new InMemoryCache({
			typePolicies: {
				Content: {
					fields: {
						contentProgress: {
							merge: true
						}
					}
				},
				Topic: {
					fields: {
						id: {
							merge(existing, incoming) {
								return !incoming ? (existing ?? null) : incoming;
							}
						},
						code: {
							merge(existing, incoming) {
								return !incoming ? (existing ?? null) : incoming;
							}
						},
						description: {
							merge(existing, incoming) {
								return !incoming ? (existing ?? null) : incoming;
							}
						},
						abbreviation: {
							merge(existing, incoming) {
								return !incoming ? (existing ?? null) : incoming;
							}
						}
					},
					merge(existing, incoming, { mergeObjects }) {
						return mergeObjects(existing || {}, incoming);
					}
				}
			}
		}),
		ssrMode: typeof window === 'undefined',
		defaultOptions: {
			watchQuery: {
				fetchPolicy: fetchPolicy?.watchQuery || 'cache-first'
			},
			query: {
				fetchPolicy: fetchPolicy?.query || 'cache-first'
			}
		}
	});
};

export function initializeApollo(
	initialState: Record<string, StoreObject> | null = null,
	options?: {
		apolloLink?: ApolloLink | null;
		fetchPolicy?: {
			watchQuery?: WatchQueryFetchPolicy;
			query?: FetchPolicy;
		};
		batchHttpLinkOptions?: BatchHttpLink.Options;
	}
) {
	const _apolloClient = apolloClient ?? createApolloClient(options);
	// If your page has Next.js data fetching methods that use Apollo Client,
	//  the initial state gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract();

		// Merge the initialState from getStaticProps/getServerSideProps
		// in the existing cache
		const data = merge(existingCache, initialState, {
			// combine arrays using object equality (like in sets)
			arrayMerge: (destinationArray, sourceArray) => [
				...sourceArray,
				...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))
			]
		});
		// Restore the cache with the merged data
		_apolloClient.cache.restore(data);
	}
	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') {
		return _apolloClient;
	}
	// Create the Apollo Client once in the client
	if (!apolloClient) {
		apolloClient = _apolloClient;
	}
	return _apolloClient;
}

export function addApolloState(
	client: ApolloClient<NormalizedCacheObject>,
	pageProps: { props: Record<string, unknown>; revalidate?: number }
) {
	if (pageProps?.props) {
		pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
	}
	return pageProps;
}

export function useApollo(pageProps: Record<string, unknown>) {
	const state = pageProps[APOLLO_STATE_PROP_NAME];
	const storeRef = useRef<ApolloClient<NormalizedCacheObject>>();
	if (!storeRef.current) {
		storeRef.current = initializeApollo(state as Record<string, StoreObject> | null);
	}
	return storeRef.current;
}

export default createApolloClient;
