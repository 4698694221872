import { MessageDescriptor } from 'react-intl';
import { Types } from '..';

export const evenFormat = [
	{
		id: Types.EventFormat.Fortbildungstage,
		localeId: 'common.event-format.fortbildungstage'
	},
	{
		id: Types.EventFormat.ExpertenForum,
		localeId: 'common.event-format.experten-forum'
	},
	{
		id: Types.EventFormat.Refresher,
		localeId: 'common.event-format.refresher'
	},
	{
		id: Types.EventFormat.RefresherAktuell,
		localeId: 'common.event-format.refresher-actuell'
	},
	{
		id: Types.EventFormat.UpdateRefresher,
		localeId: 'common.event-format.update-refresher'
	},
	{
		id: Types.EventFormat.Webup,
		localeId: 'common.event-format.webup'
	},
	{
		id: Types.EventFormat.StudyConferenceUpdate,
		localeId: 'common.event-format.study-conference-update'
	},
	{
		id: Types.EventFormat.PartnerEvent,
		localeId: 'common.event-format.partner-event'
	}
];

export const transformEventFormatToContentBrand = (
	eventFormat: Types.EventFormat
): Types.ProductBrand => {
	switch (eventFormat) {
		case 'FORTBILDUNGSTAGE':
		case 'REFRESHER':
			return eventFormat;
		case 'EXPERTEN_FORUM':
			return 'WUEX';
		case 'REFRESHER_AKTUELL':
			return 'REFRESHER_ACTUELL';
		case 'UPDATE_REFRESHER':
			return 'UPDATE_REFRESHER';
		case 'WEBUP':
			return 'WUIF';
		case 'STUDY_CONFERENCE_UPDATE':
			return 'STUDY_CONFERENCE_UPDATE';
		case 'PARTNER_EVENT':
			// TODO: support multiple brands because each tenant could have its own brands
			return 'SGMO_CURRICULUM';
	}
};

export const transformContentBrandToEventFormatText = (
	contentBrand: Types.ProductBrand,
	formatMessage: (descriptor: MessageDescriptor) => string
): string | undefined => {
	switch (contentBrand) {
		case 'FORTBILDUNGSTAGE':
			return formatMessage({ id: 'common.event-format.fortbildungstage' });
		case 'REFRESHER':
			return formatMessage({ id: 'common.event-format.refresher' });
		case 'WUEX':
			return formatMessage({ id: 'common.event-format.experten-forum' });
		case 'REFRESHER_ACTUELL':
			return formatMessage({ id: 'common.event-format.refresher-actuell' });
		case 'UPDATE_REFRESHER':
			return formatMessage({ id: 'common.event-format.update-refresher' });
		case 'WUIF':
			return formatMessage({ id: 'common.event-format.webup-focus' });
		case 'STUDY_CONFERENCE_UPDATE':
			return formatMessage({ id: 'common.event-format.study-conference-update' });
		case 'SGMO_CURRICULUM':
			return 'SGMO Curriculum'; //TODO: think about how to handle translations in case of partner events
		default:
			return undefined;
	}
};
