import React, { useState } from 'react';
import { Divider } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { Types, isFeatureEnabled, getCountryCode } from 'common';
import { Button, FullScreenDialog, ButtonsSwitch, Typography } from 'components';
import { useIntl } from 'react-intl';
import useStyles from './Filters.styles';
import {
	SortingProductEnum,
	getContentTypeButtons,
	sortingProductMenuOptions,
	lectureSourceFilterButtons,
	FilterProps
} from './Filters.types';
import { dateFilterMenuOptions, getIsDateFilterVisible } from '../../utils/filters/dateFilter';
import FilterUtils from './Filters.utils';
import TopicsSlider from '../TopicSlider';
import ActiveFiltersChips from './ActiveFiltersChips';

const Transition = React.forwardRef(function Transition(
	props: SlideProps,
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const MobileFilters = (props: FilterProps) => {
	const { allowContentTypeFilter = true, onChange } = props;
	const { locale } = useIntl();
	const countryCode = getCountryCode(locale);
	const languageDropdownOptions = FilterUtils.getDomainLanguageOptions(countryCode);
	const isLanguageFilterAvailable =
		isFeatureEnabled('contentLanguageSwitch', countryCode) &&
		languageDropdownOptions.length > 0;

	const {
		topics,
		stateQueryParams: queryParams,
		queryParams: activeQueryParams,
		handleContentTypeChange,
		handleDateChange,
		handleSourceChange,
		handleSortingChange,
		persist: persistFilters,
		clear: clearFilters,
		setAllTopics,
		handleChangeLanguage,
		resetAllFilters
	} = FilterUtils.useFilters(onChange, { shouldPersistAtEnd: true });
	const classes = useStyles();
	const [isOpen, setOpen] = useState(false);

	const handleClose = () => {
		clearFilters();
		setOpen(false);
	};

	const {
		contentTypeFilter = 'ALL',
		date,
		sortBy = SortingProductEnum.RECENT,
		lectureSource = null,
		language
	} = queryParams;

	const contentTypeOption = contentTypeFilter !== 'ALL' ? contentTypeFilter : null;
	const lectureSourceOption = lectureSource !== 'ALL' ? lectureSource : null;

	const onAcceptFilters = () => {
		persistFilters();
		setOpen(false);
	};

	let filtersCount = Object.entries(activeQueryParams).filter(
		([key, value]) => Boolean(value) && key !== 'topics'
	).length;
	filtersCount += topics.length;

	const resetFilters = () => {
		resetAllFilters();
		handleClose();
	};

	const isDateFilterVisible = getIsDateFilterVisible(contentTypeFilter);

	return (
		<>
			<ActiveFiltersChips onChange={onChange} />
			<div className={classes.filterButtonContainer}>
				<Button
					className={classes.filterButton}
					localeId="catalog.filters.button"
					minimal
					startIcon="filters"
					classes={{
						startIcon: classes.filterButtonStartIcon,
						endIcon: classes.filterButtonBadge
					}}
					onClick={() => setOpen(true)}
					endIcon={
						filtersCount && (
							<div className={classes.filterButtonBadge}>{filtersCount}</div>
						)
					}
				/>
			</div>
			<FullScreenDialog
				open={isOpen}
				onClose={handleClose}
				TransitionComponent={Transition}
				classes={{
					dialogPaper: classes.dialogPaper,
					titleContainer: classes.dialogTitleContainer,
					closeIcon: classes.closeIcon,
					dialogContent: classes.dialogContent
				}}
				titleLocaleId="catalog.filters.dialog.title"
				actions={
					<>
						<Button
							className={classes.dialogButton}
							minimal
							variant="text"
							onClick={resetFilters}
							localeId="catalog.filters.mobile.reset-filters"
						/>
						<Button
							className={classes.dialogButton}
							onClick={onAcceptFilters}
							localeId="catalog.filters.mobile.show-results"
						/>
					</>
				}
			>
				<TopicsSlider onChange={setAllTopics}>
					<>
						<Divider className={classes.divider} />
						<Typography
							localeId="catalog.search-media.content-type"
							className={classes.title}
						/>
						{allowContentTypeFilter && (
							<>
								<ButtonsSwitch
									classes={{
										container: classes.switchContainer,
										option: classes.switchOption,
										selected: classes.switchSelected
									}}
									options={getContentTypeButtons(locale)}
									selection={contentTypeOption ?? 'ALL'}
									onSelectionChange={handleContentTypeChange}
									className={classes.filter}
								/>
								{isDateFilterVisible && (
									<>
										<Divider className={classes.divider} />
										<Typography
											localeId="catalog.search-media.time"
											className={classes.title}
										/>
										<ButtonsSwitch
											classes={{
												container: classes.switchContainer,
												option: classes.switchOption,
												selected: classes.switchSelected
											}}
											options={dateFilterMenuOptions}
											selection={date || ''}
											onSelectionChange={handleDateChange}
											className={classes.filter}
										/>
									</>
								)}
								{contentTypeFilter === Types.ContentType.Lecture && (
									<>
										<Divider className={classes.divider} />
										<Typography
											localeId="catalog.search-media.source"
											className={classes.title}
										/>
										<ButtonsSwitch
											classes={{
												container: classes.switchContainer,
												option: classes.switchOption,
												selected: classes.switchSelected
											}}
											selection={lectureSourceOption ?? 'ALL'}
											onSelectionChange={handleSourceChange}
											options={lectureSourceFilterButtons}
											className={classes.filter}
										/>
									</>
								)}
								<Divider className={classes.divider} />
							</>
						)}
						<Typography
							localeId="catalog.search-media.sorting.mobile"
							className={classes.title}
						/>
						<ButtonsSwitch
							classes={{
								container: classes.switchContainer,
								option: classes.switchOption,
								selected: classes.switchSelected
							}}
							selection={sortBy}
							onSelectionChange={handleSortingChange}
							options={sortingProductMenuOptions}
							className={classes.filter}
						/>
						{isLanguageFilterAvailable && (
							<>
								<Divider className={classes.divider} />
								<Typography
									localeId="filters.language.all"
									className={classes.title}
								/>
								<ButtonsSwitch
									classes={{
										container: classes.switchContainer,
										option: classes.switchOption,
										selected: classes.switchSelected
									}}
									selection={language ?? 'ALL'}
									onSelectionChange={handleChangeLanguage}
									options={languageDropdownOptions}
									className={classes.filter}
								/>
							</>
						)}
					</>
				</TopicsSlider>
			</FullScreenDialog>
		</>
	);
};

export default MobileFilters;
