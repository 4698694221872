import React from 'react';

import { Box, Container, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import HeaderUserNavBar from '../../HeaderUserNavBar/HeaderUserNavBar';
import { useUserContext, useMembershipContext } from '../../../contexts';
import HeaderLinks from '../HeaderLinks';
import { Logo, SearchBox, Icon, DrawerMenu } from 'components';
import type { HeaderProps } from '..';
import useStyles from './Header.desktop.styles';
import { getHeaderLinks, useHeaderSearch } from '../Header.utils';
import { booleanFilter, getCountryCode, isFeatureEnabled } from '../../../utils';
import LocaleSwitch from '../LocaleSwitch';
import useHeaderHeight from '../../../hooks/useHeaderHeight';
import { CATALOG_URLS, MEDIATHEK_URLS } from '../../../urls';
import NextLink from '../../NextLink';
import { theme, useFlags } from '../../..';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTenantContext } from '../../../contexts/TenantContext';
import { TenantHeaderDesktop } from './TenantHeader.desktop';

const ContactIcons = (props: { className: string }) => {
	const classes = useStyles({});
	return (
		<Box className={classnames(props.className, classes.iconsContainer)}>
			<IconButton
				component={NextLink}
				className={classes.iconButton}
				href={CATALOG_URLS.contact}
				size="large"
			>
				<Icon icon="phone" className={classes.icon} />
			</IconButton>
			<IconButton
				component={NextLink}
				className={classes.iconButton}
				href={CATALOG_URLS.faq}
				size="large"
			>
				<Icon icon="help" className={classes.icon} />
			</IconButton>
		</Box>
	);
};

export const HeaderDesktop = (props: HeaderProps) => {
	const { drawerItems, drawerProps } = props;
	const { query, pathname } = useRouter();
	const { isLoading, isLoggedIn, user } = useUserContext();
	const { userMembership } = useMembershipContext();
	const headerHeight = useHeaderHeight();
	const flags = useFlags();
	const { formatMessage, locale } = useIntl();
	const countryCode = getCountryCode(locale);
	const { tenantConfig } = useTenantContext();

	// We need to keep 1440px as default value for maxWidth, because it's the default value in the theme,
	// and home pages. The partner forum pages are an exception for now.
	const classes = useStyles({
		maxWidth: tenantConfig?.id != null ? theme.breakpoints.values.xl : 1440
	});
	const { onClear, onSearch, onSearchChange, search } = useHeaderSearch();

	const isMediathekEnabled = isFeatureEnabled('mediathek', getCountryCode(locale));

	const headerLinks = getHeaderLinks({
		countryCode,
		isMobile: false,
		locale,
		flags,
		query,
		pathname,
		isLoggedIn
	});

	const userNavBarProps = {
		isLoggedIn,
		isLoading,
		user,
		userMembership,
		classes: {
			primaryButton: classes.primaryButton,
			link: classes.link,
			avatar: classes.avatar
		}
	};

	return (
		<DrawerMenu
			items={drawerItems ?? []}
			classes={{
				...drawerProps?.classes,
				root: [classes.root, drawerProps?.classes?.root].filter(booleanFilter).join(' ')
			}}
			header={
				<Container
					style={{ height: headerHeight }}
					maxWidth="xl"
					className={classes.container}
				>
					{tenantConfig?.id != null ? (
						<TenantHeaderDesktop
							tenantConfig={tenantConfig}
							userNavBarProps={userNavBarProps}
						/>
					) : (
						<>
							<Box className={classes.topContainer}>
								<Box className={classes.logoWrapperContainer}>
									<Box className={classes.logo}>
										<NextLink className={classes.link} href="/">
											<Logo locale={locale} />
										</NextLink>
									</Box>
									{isMediathekEnabled ? (
										<SearchBox
											placeholder={formatMessage({
												id: 'common.header.search-placeholder'
											})}
											onChange={onSearchChange}
											value={search || ''}
											onEnter={onSearch}
											onClear={onClear}
											classes={{
												root: classes.searchBoxRoot,
												inputBaseRoot: classes.searchBoxInputBaseRoot
											}}
											InputProps={{
												endAdornment: (
													<NextLink
														href={MEDIATHEK_URLS.topics}
														className={classes.topicsLink}
													>
														<Icon
															icon="listBullets"
															className={classes.topicsIcon}
														/>
														{formatMessage({
															id: 'common.header.topics'
														})}
													</NextLink>
												)
											}}
										/>
									) : (
										<Box className={classes.navbarContainer}>
											<HeaderLinks menuItems={headerLinks} />
											<LocaleSwitch />
										</Box>
									)}
								</Box>
								{isMediathekEnabled ? (
									<ContactIcons className={classes.iconsContainerMd} />
								) : (
									<ContactIcons className={classes.iconsContainerLg} />
								)}
								<Box className={classes.userInfoContainer}>
									<HeaderUserNavBar
										{...userNavBarProps}
										loginActionLocaleId="common.login.title"
									/>
								</Box>
							</Box>
							{isMediathekEnabled && (
								<Box className={classes.navbarContainer}>
									<HeaderLinks menuItems={headerLinks} />
									<Box display="flex" alignItems="center">
										<LocaleSwitch className={classes.localeSwitch} />
										<ContactIcons className={classes.iconsContainerLg} />
									</Box>
								</Box>
							)}
						</>
					)}
				</Container>
			}
			{...drawerProps}
		/>
	);
};
