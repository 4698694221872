import { Types } from 'common';
import { ComponentsTypes } from 'components';
import { DateFilterOptionEnum } from '../../utils/filters/dateFilter';
import type { TopicBreadcrumbItem } from '../../utils/topics';

export const Languages = ['de-CH', 'fr-CH'] as const;
export type FiltersLanguage = Language | 'ALL' | null | undefined;

export const CONTENT_TYPE_QUERY_PARAM_NAME = 'contentTypeFilter';

export type SearchProductQueryParams = {
	[CONTENT_TYPE_QUERY_PARAM_NAME]?: ContentContentTypeFilterType;
	search?: string | null;
	date?: DateFilterOptionEnum | null;
	lectureSource?: LectureSourceOptionEnum | null;
	sortBy?: SortingProductEnum | null;
	language?: FiltersLanguage;
};

// Content type filtering
export type ContentContentTypeFilterType =
	| Types.ContentType
	| typeof Types.ProductBrand.Summedup
	| typeof Types.ProductBrand.Skill
	| typeof Types.ProductBrand.Guideline
	| 'ALL'
	| null;

export enum ContentContentTypeFilter {
	COURSE = 'COURSE',
	LECTURE = 'LECTURE',
	WEBINAR = 'WEBINAR',
	SUMMEDUP = 'SUMMEDUP',
	SKILL = 'SKILL',
	GUIDELINE = 'GUIDELINE',
	ALL = 'ALL'
}

const allContentTypeOptionsUK = [
	{ id: Types.ContentType.Webinar, localeId: 'common.product-type.webinar' },
	{ id: Types.ContentType.Course, localeId: 'common.product-type.course' },
	{ id: Types.ContentType.Lecture, localeId: 'common.product-type.lecture' },
	{ id: Types.ProductBrand.Guideline, localeId: 'common.product-type.guideline' }
];

const allContentTypeOptions = [
	{ id: Types.ProductBrand.Summedup, localeId: 'common.product-brand.summed-up' },
	{ id: Types.ContentType.Webinar, localeId: 'common.product-type.webinar' },
	{ id: Types.ContentType.Course, localeId: 'common.product-type.course' },
	{ id: Types.ContentType.Lecture, localeId: 'common.product-type.lecture' },
	{ id: Types.ProductBrand.Skill, localeId: 'common.product-type.skill' },
	{ id: Types.ProductBrand.Guideline, localeId: 'common.product-type.guideline' }
];

type GetContentTypeSectionsType = (
	locale: Locale
) => Array<Array<ComponentsTypes.SelectableOption<Exclude<ContentContentTypeFilterType, null>>>>;

export const getContentTypeSections: GetContentTypeSectionsType = (locale: Locale) => {
	return [
		[{ id: 'ALL', localeId: 'catalog.search-media.content-type.all' }],
		locale === 'en-GB' ? allContentTypeOptionsUK : allContentTypeOptions
	];
};

export const getContentTypeButtons = (locale: Locale) => [
	{ id: 'ALL', localeId: 'catalog.search-media.content-type.all' },
	...(locale === 'en-GB' ? allContentTypeOptionsUK : allContentTypeOptions)
];

// Lectures filtering
export type LectureSourceFilterType = 'AM' | 'IM' | 'SPECIAL_COURSES' | 'ALL' | null;

export enum LectureSourceOptionEnum {
	GENERAL_MEDICINE = 'AM',
	INTERNAL_MEDICINE = 'IM',
	SPECIAL_COURSES = 'SPECIAL_COURSES',
	ALL = 'ALL'
}

export const lectureSourceFilterMenuSections: Array<
	Array<ComponentsTypes.SelectableOption<Exclude<LectureSourceFilterType, null>>>
> = [
	[{ id: 'ALL', localeId: 'catalog.search-media.content-type.all' }],
	[
		{
			id: LectureSourceOptionEnum.GENERAL_MEDICINE,
			localeId: 'catalog.search.general_medicine.menu'
		},
		{
			id: LectureSourceOptionEnum.INTERNAL_MEDICINE,
			localeId: 'catalog.search.internal_medicine.menu'
		},
		{
			id: LectureSourceOptionEnum.SPECIAL_COURSES,
			localeId: 'catalog.search.special_courses.menu'
		}
	]
];

export const lectureSourceFilterButtons = [
	{ id: 'ALL', localeId: 'catalog.search-media.content-type.all' },

	{
		id: LectureSourceOptionEnum.GENERAL_MEDICINE,
		localeId: 'catalog.search.general_medicine.menu'
	},
	{
		id: LectureSourceOptionEnum.INTERNAL_MEDICINE,
		localeId: 'catalog.search.internal_medicine.menu'
	},
	{
		id: LectureSourceOptionEnum.SPECIAL_COURSES,
		localeId: 'catalog.search.special_courses.menu'
	}
];

export const lectureSourceOptions = lectureSourceFilterMenuSections.flatMap(
	(lectureSourceOptions) =>
		lectureSourceOptions.map((lectureSourceOption) => lectureSourceOption.id)
);

export const lectureSourceFilterOptions = [
	LectureSourceOptionEnum.GENERAL_MEDICINE,
	LectureSourceOptionEnum.INTERNAL_MEDICINE,
	LectureSourceOptionEnum.SPECIAL_COURSES
];

// Sorting
export type SortingProduct = 'RECENT' | 'RELEVANCE';
export const enum SortingProductEnum {
	'RECENT' = 'RECENT',
	'RELEVANCE' = 'RELEVANCE'
}

export const sortingProductMenuOptions = [
	{ id: SortingProductEnum.RECENT, localeId: 'catalog.search-media.recent' },
	{ id: SortingProductEnum.RELEVANCE, localeId: 'catalog.search-media.relevance' }
];

export const sortingProductOptions = sortingProductMenuOptions.map(
	(sortingProductOption) => sortingProductOption.id
);

export const enum SortGuidelinesEnum {
	'RECENT' = 'RECENT',
	'ALPHABETICAL' = 'ALPHABETICAL'
}

export const sortGuidelinesMenuOptions = [
	{ id: SortGuidelinesEnum.ALPHABETICAL, localeId: 'catalog.sort-by.az' },
	{ id: SortGuidelinesEnum.RECENT, localeId: 'catalog.sort-by.date' }
];

export type DocumentsCountByLanguage = Record<Language, number>;

export type FilterProps = {
	/**
	 * @default true
	 */
	allowContentTypeFilter?: boolean;

	onChange: (topic?: TopicBreadcrumbItem) => void;
	countByLanguage?: DocumentsCountByLanguage;
};
