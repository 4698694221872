import React from 'react';
import { makeStyles } from '@mui/styles';
import { CourseProductIcon } from '../shared/CourseProductIcon';
import { CoursesTableProps, CourseTableItem } from '../CoursesTable';
import { Types } from 'common';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		gap: theme.spacing(2)
	},
	multiproductContainer: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		gap: theme.spacing(2)
	},
	courseSubtitle: {
		fontWeight: 400
	},
	courseTitleTextContainer: {
		display: 'flex',
		alignItems: 'center',
		fontWeight: 'normal',
		whiteSpace: 'pre'
	},
	courseTopicTitle: {
		fontWeight: 700,
		display: 'inline-block',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		maxWidth: 280
	}
}));

type CourseProductProps = Pick<
	CourseTableItem,
	'courseTitle' | 'courseIconUrl' | 'courseIconColor' | 'subtitle'
> &
	Pick<CoursesTableProps, 'variant'>;

export const CourseTitle = (props: CourseProductProps) => {
	const classes = useStyles();
	const isCMED = useIntl().locale === 'en-GB';

	const { courseTitle, courseIconUrl, courseIconColor, variant, subtitle } = props;
	if (variant === 'MULTIPRODUCT') {
		return (
			<div className={classes.titleContainer}>
				<CourseProductIcon url={courseIconUrl} color={courseIconColor} />
				<div>
					<div className={classes.courseTitleTextContainer}>
						<span className={classes.courseTopicTitle}>{courseTitle.title}</span>
						{' | ' + courseTitle.brand}
					</div>
					{subtitle && <span className={classes.courseSubtitle}>{subtitle}</span>}
				</div>
			</div>
		);
	}
	if (variant === Types.ProductBrand.Ondemand || variant === Types.ProductBrand.Syfat) {
		return (
			<div className={classes.titleContainer}>
				<CourseProductIcon url={courseIconUrl} color={courseIconColor} />
				<div className={classes.courseTitleTextContainer}>
					<span className={classes.courseTopicTitle}>{courseTitle.title}</span>
					{isCMED && subtitle && <span>{' | ' + subtitle}</span>}
				</div>
			</div>
		);
	}
	return null;
};
