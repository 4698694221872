import React from 'react';
import { HeaderDesktop } from './Header.desktop/Header.desktop';
import { HeaderMobile } from './Header.mobile/Header.mobile';
import { DrawerMenuProps } from 'components';
import TenantBreadcrumbs from './Header.desktop/TenantBreadcrumbs';

export { TenantBreadcrumbs };

export type HeaderQueryParams = {
	search?: string | null;
};

export type HeaderProps = {
	drawerItems?: DrawerMenuProps['items'];
	drawerProps?: Partial<DrawerMenuProps>;
};

export const Header = (props: HeaderProps) => {
	return (
		<>
			<HeaderMobile {...props} />
			<HeaderDesktop {...props} />
		</>
	);
};
