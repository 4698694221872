import { useState } from 'react';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { useRouter } from '../../hooks';
import { USER_URLS, MEDIATHEK_URLS, CATALOG_URLS, BOOKING_URLS } from '../../urls';
import {
	HeaderLinksProps,
	HeaderLinkChildItem,
	HeaderLinkItemBase,
	HeaderLinkParentItem
} from './HeaderLinks.types';
import isFeatureEnabled, { Features } from '../../utils/isFeatureEnabled';
import getLocalizedLink, {
	getKeyForLocalizedLink,
	LocalizedLink
} from '../../utils/getLocalizedLink';
import { HeaderQueryParams } from '.';
import { IconName, MenuItem } from 'components';
import { ParsedUrlQuery } from 'node:querystring';
import { booleanFilter } from '../../utils';

const scientificBoardPath = `wissenschaftliches_kollegium`;
const scientificBoardURL = `/${scientificBoardPath}`;
const scientificBoardUrlByDomain: LocalizedLink = {
	de: scientificBoardURL,
	'de-CH': scientificBoardURL,
	'fr-CH': `/fr/${scientificBoardPath}`,
	at: scientificBoardURL
};

const getHeaderLinkForLocalizedLink = ({
	url,
	localeId,
	locale,
	mediaQuery,
	showWhenFeatureAvailable,
	hideWhenFeatureAvailable,
	showWhenFeatureFlagAvailable,
	...rest
}: {
	url: LocalizedLink;
	localeId: string;
	locale: Locale;
	showWhenFeatureAvailable?: keyof Features;
	showWhenFeatureFlagAvailable?: (flags: LDFlagSet) => boolean;
	hideWhenFeatureAvailable?: keyof Features;
	mediaQuery?: 'desktop' | 'mobile';
} & Pick<
	HeaderLinkItemBase,
	'iconName' | 'iconColor' | 'iconColorVariant'
>): Array<HeaderLinkChildItem> => {
	const domainForLink = getKeyForLocalizedLink(locale);
	if (url[domainForLink as DomainForLinks]) {
		const href = getLocalizedLink(url, locale);
		return [
			{
				localeId,
				href,
				showWhenFeatureAvailable,
				hideWhenFeatureAvailable,
				mediaQuery,
				showWhenFeatureFlagAvailable,
				...rest
			}
		];
	}
	return [];
};

const LOCALE_TO_CREDIT_ICON: Record<Locale, IconName> = {
	'de-DE': 'cme',
	'de-AT': 'dfp',
	'de-CH': 'creditsDeCh',
	'fr-CH': 'creditsFrCh',
	'en-GB': 'cme'
};

const getDefaultLinks = (locale: Locale): HeaderLinksProps['menuItems'] => [
	[
		{
			localeId: 'common.header.home-site',
			href: CATALOG_URLS.base,
			iconName: 'homeFill',
			iconColor: 'secondary'
		},
		{
			localeId: 'common.header.my-fomf',
			href: USER_URLS.dashboard,
			showWhenLoggedIn: true
		}
	],
	[
		{
			href: CATALOG_URLS.allCourses,
			localeId: 'common.header.link-cme-course'
		},
		// if UK, then insert a webup link at the top level, instead of the submenu
		...(locale === 'en-GB'
			? [
					{
						localeId: 'common.header.link-webup',
						href: MEDIATHEK_URLS.webup
					}
				]
			: []),
		{
			localeId: 'common.header.link.all-courses',
			showWhenFeatureAvailable: 'mediathek',
			secondLevelLinks: [
				{
					href: CATALOG_URLS.livestream,
					localeId: 'common.header.link-livestream',
					showWhenFeatureAvailable: 'livestreamPage',
					iconName: 'live'
				},
				{
					href: CATALOG_URLS.allCourses,
					localeId: 'common.header.link-cme-course',
					iconName: LOCALE_TO_CREDIT_ICON[locale]
				},
				{
					href: CATALOG_URLS.onDemandLanding,
					localeId: 'common.header.link-cme-on-demand-course',
					showWhenFeatureFlagAvailable: (flags) => {
						switch (locale) {
							case 'de-DE':
								return true;
							case 'de-CH':
							case 'fr-CH':
								return flags.learningOnDemandAccreditationChTemp14102024;
							case 'de-AT':
								return flags.learningOnDemandAccreditationAtTemp14102024;
							default:
								return false;
						}
					},
					iconName: 'progressPlay'
				},
				{
					localeId: 'common.header.link-webup',
					href: MEDIATHEK_URLS.webup,
					iconName: 'webUp'
				},
				{
					localeId: 'common.header.link.syfat',
					href: CATALOG_URLS.syfatLanding,
					showWhenFeatureFlagAvailable: (flags) => {
						switch (locale) {
							case 'de-DE':
							case 'de-AT':
								return true;
							case 'de-CH':
							case 'fr-CH':
								return flags.learningSyfatForChMembersTemp01102024;
							default:
								return false;
						}
					},
					iconName: 'syfat'
				},
				{
					localeId: 'common.header.link-summed-up',
					href: MEDIATHEK_URLS.summedup,
					showWhenFeatureAvailable: 'summedUpPage',
					iconName: 'summedUp'
				}
			]
		},
		{
			localeId: 'common.header.link.medkit',
			showWhenFeatureAvailable: 'mediathek',
			secondLevelLinks: [
				{
					localeId: 'common.header.link-skills',
					href: MEDIATHEK_URLS.skills,
					showWhenFeatureAvailable: 'mediathek',
					iconName: 'skills'
				},
				{
					localeId: 'common.header.link-guidelines',
					href: MEDIATHEK_URLS.guidelines,
					showWhenFeatureAvailable: 'mediathek',
					iconName: 'guidelines'
				}
			]
		},
		// if UK, then insert a Guidelines page link at the top level, instead of the submenu
		...(locale === 'en-GB'
			? [
					{
						localeId: 'common.header.link-guidelines',
						href: MEDIATHEK_URLS.guidelines
					}
				]
			: []),
		{
			localeId: 'common.header.link-partnerforum',
			href: CATALOG_URLS.partners + '/videos',
			showWhenFeatureAvailable: 'partnerForum'
		}
	],
	[
		...getHeaderLinkForLocalizedLink({
			url: scientificBoardUrlByDomain,
			localeId: 'common.header.scientific-board',
			locale
		})
	],
	[
		{
			localeId: 'common.header.link-membership',
			showWhenFeatureAvailable: 'memberships',
			iconName: 'membership',
			color: 'primary',
			colorVariant: '600',
			secondLevelLinks: [
				{
					localeId: 'common.header.link.user-membership',
					href: BOOKING_URLS.memberships,
					iconName: 'userFill'
				},
				{
					localeId: 'common.header.link.group-membership',
					href: '/gruppenmitgliedschaft',
					iconName: 'userGroupFill'
				}
			]
		}
	]
];

export const getHeaderLinks = (args: {
	countryCode: CountryCode;
	isMobile: boolean;
	locale: Locale;
	flags: LDFlagSet;
	pathname: string;
	query: ParsedUrlQuery;
	isLoggedIn: boolean;
}): HeaderLinksProps['menuItems'] => {
	const { countryCode, isMobile, locale, flags, pathname, query, isLoggedIn } = args;
	const menuItemFilter = (menu: HeaderLinkItemBase) => {
		const isFrontendFeatureEnabled =
			menu.showWhenFeatureAvailable != null &&
			isFeatureEnabled(menu.showWhenFeatureAvailable, countryCode);
		const isLDFlagEnabled =
			menu.showWhenFeatureFlagAvailable != null && menu.showWhenFeatureFlagAvailable(flags);

		if (menu.showWhenFeatureAvailable) {
			if (menu.showWhenFeatureFlagAvailable) {
				return isFrontendFeatureEnabled && isLDFlagEnabled;
			}
			return isFrontendFeatureEnabled;
		}
		if (menu.showWhenFeatureFlagAvailable) {
			if (menu.showWhenFeatureAvailable) {
				return isFrontendFeatureEnabled && isLDFlagEnabled;
			}
			return isLDFlagEnabled;
		}

		if (
			menu.hideWhenFeatureAvailable &&
			isFeatureEnabled(menu.hideWhenFeatureAvailable, countryCode)
		) {
			return false;
		}

		if (menu.mediaQuery === (isMobile ? 'desktop' : 'mobile')) {
			return false;
		}

		if (menu.showWhenLoggedIn && !isLoggedIn) {
			return false;
		}

		return true;
	};

	const addIsLinkActiveMap = <T extends HeaderLinkChildItem | HeaderLinkParentItem>(
		headerLinkItem: T,
		isSecondLevel?: boolean
	): T => {
		const href = 'href' in headerLinkItem ? headerLinkItem.href : '';
		const secondLevelLinks =
			'secondLevelLinks' in headerLinkItem ? headerLinkItem.secondLevelLinks : [];

		const allLinks = [href, ...secondLevelLinks.map((link) => link.href)];
		const allLinksFiltered = allLinks
			.filter(booleanFilter)
			// Special handling of cme course link shown twice. We don't want to mark both menu items as active
			.filter((href, index) => !(href === cmeCourseLinkUrl && (index > 0 || isSecondLevel)));

		const isActive = allLinksFiltered.some((href) =>
			getIsLinkActive({ href, pathname, query })
		);

		return {
			...headerLinkItem,
			isActive
		};
	};

	const defaultLinks = getDefaultLinks(locale);

	const cmeCourseLinkUrl = defaultLinks.reduce<string | undefined>((acc, items) => {
		if (acc) {
			return acc;
		}

		let cmeCourseLinkItem: HeaderLinkChildItem | undefined;
		items.forEach(
			(item) =>
				'secondLevelLinks' in item &&
				item.secondLevelLinks.forEach((link) => {
					if (link.localeId === 'common.header.link-cme-course') {
						cmeCourseLinkItem = link;
					}
				})
		);
		return (
			(cmeCourseLinkItem && 'href' in cmeCourseLinkItem && cmeCourseLinkItem.href) ||
			undefined
		);
	}, undefined);

	const result = defaultLinks
		.map((each) =>
			each
				.filter(menuItemFilter)
				.map((each) => ({
					...each,
					...('secondLevelLinks' in each
						? {
								secondLevelLinks: each.secondLevelLinks
									.filter(menuItemFilter)
									.map((link) => addIsLinkActiveMap(link, true))
							}
						: undefined)
				}))
				.map((link) => addIsLinkActiveMap(link))
		)
		.filter((each) => each.length > 0);

	return result;
};

export const userLogoutSubMenuLinks = (locale: Locale): Array<MenuItem> => [
	{
		localeId: 'common.header.user-navbar.popover.logout',
		onClick: USER_URLS.logout({ locale }),
		color: 'error',
		colorVariant: 'dark',
		iconName: 'logout',
		iconColor: 'primary',
		iconColorVariant: 'main'
	}
];

export const userSubMenuLinks = (locale: Locale): Array<Array<MenuItem>> => [
	[
		{
			localeId: 'common.header.my-cme',
			href: USER_URLS.dashboard,
			color: 'secondary',
			colorVariant: '800',
			iconName: 'event',
			iconColor: 'primary',
			iconColorVariant: 'main'
		},
		{
			localeId: 'common.header.user-navbar.popover.user-management',
			href: USER_URLS.profile,
			color: 'secondary',
			colorVariant: '800',
			iconName: 'accountCircle',
			iconColor: 'primary',
			iconColorVariant: 'main'
		},
		{
			localeId: 'common.header.user-navbar.popover.membership',
			href: USER_URLS.dashboardMembership,
			color: 'secondary',
			colorVariant: '800',
			iconName: 'fomfShape',
			iconColor: 'primary',
			iconColorVariant: 'main'
		}
	],
	userLogoutSubMenuLinks(locale)
];

export const useHeaderSearch = () => {
	const router = useRouter<HeaderQueryParams>();
	const {
		query: { search: searchQueryParam = null },
		setQuery,
		pathname
	} = router;
	const [search, setSearch] = useState<string | null>(searchQueryParam);

	const onSearch = () => {
		// Don't redirect to search page if search input is empty
		if (!search) {
			return;
		}
		router.push(`${MEDIATHEK_URLS.search}?search=${search}&sortBy=RELEVANCE`);
	};

	const onSearchChange = (text: string) => {
		setSearch(text || null);
	};

	const onClear = () => {
		// only open search page on clearing when already on search page
		if (searchQueryParam === search && pathname === MEDIATHEK_URLS.search) {
			setQuery({ search: null });
			router.push(MEDIATHEK_URLS.search);
		} else {
			setSearch(null);
		}
	};

	return {
		search,
		onSearch,
		onSearchChange,
		onClear
	};
};

/**
 * This function is used to replace the query params in the url with the actual values. Example: `/product/[code]` -> `/product/AM`.
 */
export const replaceNextJSPageRoutingQueryParamsWithActualValues = (
	url: string,
	query: NodeJS.Dict<string | Array<string>>
) => {
	let newUrl = url;
	if (Object.keys(query).length > 0) {
		for (const pair of Object.entries(query)) {
			let value = pair[1];
			const key = pair[0];
			if (value) {
				if (Array.isArray(value)) {
					value = value.join('/');
				}

				newUrl = newUrl.replace(`[${key}]`, value);
				newUrl = newUrl.replace(`[...${key}]`, value);
			}
		}
	}
	return newUrl;
};

/**
 * This function is used to normalise the url to be relative to the current domain. Additionally, it removes the query string, hash, coalesces double slashes into a single one and removes a trailing slash.
 */
export const normaliseUrl = (url: string) => {
	let newUrl = url;

	if (typeof window !== 'undefined' && window.location.origin) {
		newUrl = url.replace(window.location.origin, '');
	}

	newUrl = newUrl.replace(/\/$/, '');
	newUrl = newUrl.replace(/\?.*$/, '');
	newUrl = newUrl.replace(/#.*$/, '');
	newUrl = newUrl.replace(/(?:\/)?fr-CH/, '');
	if (newUrl === '') {
		newUrl = '/';
	}
	if (newUrl[0] !== '/') {
		newUrl = `/${newUrl}`;
	}
	newUrl = newUrl.replace(/([^:/]|^)\/\/+/g, '$1/');
	return newUrl;
};

export const getIsLinkActive = (args: {
	href: string | null | undefined;
	pathname: string;
	query: ParsedUrlQuery;
}) => {
	const { href, pathname, query } = args;
	const relativeNormalisedUrl = href && normaliseUrl(href);
	const normalisedNextJSPageUrl = replaceNextJSPageRoutingQueryParamsWithActualValues(
		pathname,
		query
	);

	const isActive =
		relativeNormalisedUrl === normalisedNextJSPageUrl ||
		(relativeNormalisedUrl === '/partner-fortbildungsforum/videos' &&
			normalisedNextJSPageUrl === '/partner-fortbildungsforum/partners');

	return isActive;
};
