import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import { Link, Typography } from 'components';

import { CATALOG_URLS } from '../../../urls';
import { buildURLWithParams } from '../../../utils';
import { useTenantContext } from '../../../contexts/TenantContext';

const useStyles = makeStyles((theme) => ({
	navLinks: {
		display: 'flex',
		color: theme.palette.grey[500],
		alignItems: 'center',
		flexWrap: 'wrap'
	},
	navItem: {
		[theme.breakpoints.down('sm')]: {
			marginTop: 2
		},
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(2)
		},
		marginRight: theme.spacing(2),
		flexWrap: 'nowrap',
		fontWeight: 400,
		textTransform: 'uppercase',
		'-webkit-line-clamp': 1,
		display: '-webkit-box',
		overflow: 'hidden',
		'-webkit-box-orient': 'vertical'
	},
	fomfNavItem: {
		fontWeight: 400,
		color: theme.palette.primary.main
	},
	logoContainer: {
		display: 'flex',
		marginRight: theme.spacing(2),
		color: theme.palette.primary.main
	},
	logo: {
		width: '24px',
		height: '24px',
		marginRight: '8px',
		color: theme.palette.primary.main
	},
	partnerforum: {
		color: '#00000061'
	},
	currentNavItem: {
		color: theme.palette.grey[500]
	},
	link: {
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

const TenantBreadcrumbs = () => {
	const { tenantConfig } = useTenantContext();
	const classes = useStyles();
	const slug = tenantConfig?.companyPage?.slug || '';
	const companyName = slug.toUpperCase() || '';
	const contentBreadcrumb = tenantConfig?.contentTitle || '';
	return (
		<Box className={classes.navLinks}>
			<Link href={CATALOG_URLS.base} className={classes.link}>
				<Box className={classes.logoContainer}>
					<img
						className={classes.logo}
						src="https://bk-public-prod.storage.googleapis.com/public/static/icon-fomf-logo-dark.svg"
						alt="FOMF"
					/>
					<Typography
						variant="body1"
						className={classes.fomfNavItem}
						localeId="common.components.header.tenantHeader.fomf"
					/>
				</Box>
			</Link>
			/
			{contentBreadcrumb ? (
				<Link
					href={buildURLWithParams(CATALOG_URLS.partner, [slug || '', 'all'])}
					className={classes.link}
				>
					<Typography
						variant="body1"
						className={classNames(classes.navItem, {
							[classes.partnerforum]: contentBreadcrumb
						})}
						localeId="common.components.header.tenantHeader.tenant"
						localeValues={{ companyName }}
					/>
				</Link>
			) : (
				<Typography
					variant="body1"
					className={classes.navItem}
					localeId="common.components.header.tenantHeader.tenant"
					localeValues={{ companyName }}
				/>
			)}
			{contentBreadcrumb && (
				<>
					/
					<Typography
						variant="body1"
						className={classNames(classes.navItem, classes.currentNavItem)}
						title={contentBreadcrumb}
					/>
				</>
			)}
		</Box>
	);
};

export default TenantBreadcrumbs;
