import { IconName } from 'components';
import { Types } from '../';

export type EventFormatInfo = { label: string; icon: IconName; localeId: string };

const eventFormatOptions: Record<Types.EventFormat, EventFormatInfo> = {
	[Types.EventFormat.UpdateRefresher]: {
		label: 'Update Refresher',
		icon: 'refresher' as const,
		localeId: 'common.event-format.update-refresher'
	},
	[Types.EventFormat.Refresher]: {
		label: 'Refresher',
		icon: 'refresher' as const,
		localeId: 'common.event-format.refresher'
	},
	[Types.EventFormat.StudyConferenceUpdate]: {
		label: 'Studien- und Kongressupdate',
		icon: 'refresher' as const,
		localeId: 'common.event-format.study-conference-update'
	},
	[Types.EventFormat.RefresherAktuell]: {
		label: 'Refresher Aktuell',
		icon: 'refresher' as const,
		localeId: 'common.event-format.refresher-actuell'
	},
	[Types.EventFormat.ExpertenForum]: {
		label: 'Experten-Forum',
		icon: 'refresher' as const,
		localeId: 'common.event-format.experten-forum'
	},
	[Types.EventFormat.Fortbildungstage]: {
		label: 'Fortbildungstage',
		icon: 'refresher' as const,
		localeId: 'common.event-format.fortbildungstage'
	},
	[Types.EventFormat.Webup]: {
		label: 'WebUp',
		icon: 'webUp' as const,
		localeId: 'common.event-format.webup'
	},
	[Types.EventFormat.PartnerEvent]: {
		label: 'Partner Event',
		icon: 'refresher' as const,
		localeId: 'common.event-format.partner-event'
	}
};

export const getEventFormatInfo = (eventFormat: Types.EventFormat): EventFormatInfo => {
	return eventFormatOptions[eventFormat];
};

export default getEventFormatInfo;
