import React, {
	useEffect,
	useMemo,
	ReactElement,
	ReactNode,
	useState,
	PropsWithChildren
} from 'react';
import '../intlTel.css';
import App, { type AppContext, type AppProps } from 'next/app';
import { createIntl, createIntlCache, IntlProvider, useIntl } from 'react-intl';
import Head from 'next/head';
import { CacheProvider, EmotionCache } from '@emotion/react';

import {
	AlertContextProvider,
	createEmotionCache,
	DayJsHOC,
	defaultIntlProviderProps,
	env,
	GTM,
	HIDE_TIMEOUT,
	HydrationContextProvider,
	LDProvider,
	MembershipContextProvider,
	OG,
	QueryParamsContextProvider,
	SegmentContextProvider,
	TenantContextProvider,
	SEO,
	theme,
	Types,
	useLDClient,
	usePageTracking,
	UserContextProvider,
	useRemoveServerStyles,
	useUserContext,
	useApollo,
	isMobileUserAgent,
	setYupLocale,
	PrivacyPolicyModal,
	getAnalytics,
	SegmentAnalytics,
	buildLocaleMessages
} from 'common';
import { useRouter } from 'next/router';
import { CssBaseline } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { Link, ThemeProvider } from 'components';

import German from '../content/locales/de.json';
import English from '../content/locales/en.json';
import GermanAT from '../content/locales/de-AT.json';
import GermanCH from '../content/locales/de-CH.json';
import FrenchCH from '../content/locales/fr-CH.json';

import { getCatalogTheme } from '../utils';
import getSeoDictionary from '../utils/seo';
import getOgDictionary from '../utils/og';
import type { NextComponentType, NextPageContext } from 'next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { MenuItem } from 'components/src/DrawerMenu/DrawerMenu.types';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { getTenantConfigFromPathname, getTenantsConfiguration } from '../utils/tenant';

export type LayoutDataRef = {
	menuItemsHeader?: ReactNode;
	menuItems?: Array<Array<MenuItem>>;
	menuItemsFooter?: ReactNode;
} | null;

type PageProps = {
	isMobile: boolean | null | undefined;
	setDataRef?: (dataRef: LayoutDataRef) => void;
	anonymousId: string | null;
};

type GetLayout = (
	args: PropsWithChildren & {
		isMobile?: boolean | null | undefined;
		dataRef?: LayoutDataRef;
	}
) => ReactElement;

export type NextPageWithLayout<T = unknown> = NextComponentType<
	NextPageContext,
	unknown,
	PageProps & T
> & {
	getLayout?: GetLayout;
};

export type AppPropsWithLayout = Omit<AppProps, 'router'> & {
	Component: NextPageWithLayout;
	emotionCache: EmotionCache;
	pageProps: PageProps;
};

const clientSideEmotionCache = createEmotionCache();

const Content = ({ Component, pageProps }: AppPropsWithLayout) => {
	const intl = useIntl();
	const router = useRouter();
	const seoDictionary = getSeoDictionary(intl);
	const ogDictionary = getOgDictionary(intl);
	const ldClient = useLDClient();
	const userContext = useUserContext();
	const [segmentAnalytics, setSegmentAnalytics] = useState<SegmentAnalytics>();

	useEffect(() => {
		getAnalytics().then((analytics) => setSegmentAnalytics(analytics));
	}, []);

	// Identify user for LaunchDarkly
	useEffect(() => {
		if (userContext.isLoading) {
			return;
		}
		if (userContext.isLoggedIn && userContext.user?.email) {
			ldClient?.identify({
				kind: 'user',
				key: userContext.user?.id,
				email: userContext.user?.email
			});
		} else if (segmentAnalytics) {
			// anonymousId is set on server side
			if (pageProps.anonymousId) {
				segmentAnalytics?.segment?.setAnonymousId(pageProps.anonymousId);
			}
			const segmentAnonId = segmentAnalytics?.segment?.user()?.anonymousId()?.toString();
			if (segmentAnonId) {
				ldClient?.identify({
					kind: 'user',
					key: segmentAnonId || undefined
				});
			}
		}
	}, [userContext, segmentAnalytics]);

	const [dataRef, setDataRef] = useState<LayoutDataRef>(null);

	const Layout = Component.getLayout ?? DefaultLayout;

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width"
				/>
				<link rel="shortcut icon" href="/favicon.svg" />
				<link rel="apple-touch-icon" sizes="57x57" href="/favicon.svg" />
				<link rel="apple-touch-icon" sizes="180x180" href="/favicon.svg" />
			</Head>
			<SEO
				pathname={router.pathname}
				seoDictionary={seoDictionary}
				indexSetting={Types.SeoIndexSetting.IndexedFollow}
			/>
			<OG pathname={router.pathname} ogDictionary={ogDictionary} />
			<GTM />
			<CssBaseline />
			<Layout
				isMobile={pageProps.isMobile}
				dataRef={dataRef}
				tenantConfig={pageProps.tenantConfig}
			>
				<PrivacyPolicyModal />
				<Component {...pageProps} setDataRef={setDataRef} />
				<Analytics />
			</Layout>
			<SpeedInsights route={router.pathname} />
		</>
	);
};

setYupLocale();

const getStrings = (locale: Locale) => {
	return buildLocaleMessages(locale, {
		'en-GB': English,
		'de-DE': German,
		'de-CH': GermanCH,
		'de-AT': GermanAT,
		'fr-CH': FrenchCH
	});
};

const cache = createIntlCache();
export const serverSideIntl = (locale: string) => {
	return createIntl(
		{
			locale: locale,
			messages: getStrings(locale as Locale)
		},
		cache
	);
};

Link.isNextApp = true;

const MyApp = (appProps: AppPropsWithLayout) => {
	const { emotionCache = clientSideEmotionCache, pageProps, Component } = appProps;

	pageProps.isMobile =
		typeof pageProps.isMobile === 'boolean'
			? pageProps.isMobile
			: typeof window !== 'undefined'
				? isMobileUserAgent(window.navigator.userAgent)
				: undefined;

	const client = useApollo(pageProps);
	const router = useRouter();
	const locale = (router?.locale || router?.defaultLocale) as Locale;
	const messages = useMemo(() => getStrings(locale), [locale]);
	useRemoveServerStyles();
	usePageTracking();

	return (
		<IntlProvider {...defaultIntlProviderProps} locale={locale} messages={messages}>
			<CacheProvider value={emotionCache}>
				<TenantContextProvider
					tenantConfig={pageProps.tenantConfig}
					tenantsConfigs={pageProps.tenantsConfig}
					messages={messages}
				>
					<ThemeProvider
						theme={{ ...getCatalogTheme(theme), isMobile: pageProps.isMobile }}
					>
						<SnackbarProvider autoHideDuration={HIDE_TIMEOUT}>
							<AlertContextProvider>
								<ApolloProvider client={client}>
									<HydrationContextProvider>
										<UserContextProvider>
											<MembershipContextProvider>
												<LDProvider
													clientSideID={env.LAUNCHDARKLY_CLIENT_SIDE_ID}
												>
													<QueryParamsContextProvider>
														<LocalizationProvider
															dateAdapter={AdapterDayjs}
														>
															<DayJsHOC>
																<SegmentContextProvider>
																	<Content
																		Component={Component}
																		pageProps={pageProps}
																		emotionCache={emotionCache}
																	/>
																</SegmentContextProvider>
															</DayJsHOC>
														</LocalizationProvider>
													</QueryParamsContextProvider>
												</LDProvider>
											</MembershipContextProvider>
										</UserContextProvider>
									</HydrationContextProvider>
								</ApolloProvider>
							</AlertContextProvider>
						</SnackbarProvider>
					</ThemeProvider>
				</TenantContextProvider>
			</CacheProvider>
		</IntlProvider>
	);
};

MyApp.getInitialProps = async (appContext: AppContext) => {
	const appProps = (await App.getInitialProps?.(appContext)) || {};
	const tenantsConfig = await getTenantsConfiguration();
	const { formatMessage } = serverSideIntl(appContext.router.locale as Locale);
	const tenantConfig = await getTenantConfigFromPathname(
		tenantsConfig,
		appContext.router.asPath,
		appContext.router.query,
		formatMessage
	);
	return {
		...appProps,
		pageProps: {
			...appProps.pageProps,
			tenantsConfig,
			tenantConfig
		}
	};
};

export default MyApp;
