import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			background: `linear-gradient(141deg, ${theme.palette.secondary.main}33, #FFF 300px)`,
			[theme.breakpoints.up('md')]: {
				background: `linear-gradient(141deg, ${theme.palette.secondary.main}33, #FFF 500px)`
			}
		},
		wrappedContainer: {
			padding: theme.spacing(3, 0, 0, 0)
		},
		banner: {
			padding: theme.spacing(0, 3)
		},
		horizontalListContainer: {
			[theme.breakpoints.up('lg')]: {
				marginLeft: theme.spacing(-3)
			}
		},
		topicsContainer: {
			padding: theme.spacing(0, 3)
		},
		// remove once feature flag is removed
		topicSelectorContainer: {
			width: '100%'
		},
		sectionMenuContainer: {
			margin: theme.spacing(4, 2)
		},
		webupSectionMenuContainer: {
			margin: theme.spacing(4, 0)
		},
		topicHorizontalListContainer: {
			marginTop: theme.spacing(3)
		},
		blue: {
			color: theme.palette.secondary.main
		},
		lightBlue: {
			color: theme.palette.secondary[400]
		},
		filtersAndSortingHeader: {
			display: 'flex',
			marginTop: theme.spacing(2),
			alignItems: 'center',
			padding: theme.spacing(0)
		},
		topicDropdown: {
			minWidth: 200,
			margin: theme.spacing(2, 1, 0, 1),
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				width: 'unset'
			}
		}
	}),
	{ name: 'Home', index: 11 }
);

export default useStyles;
