import React, { useState } from 'react';
import classnames from 'classnames';
import { Box } from '@mui/material';
import AddonCard from '../../../../components/AddonCard/AddonCard';
import AddonCardSkeleton from '../../../../components/AddonCard/AddonCard.Skeleton';
import {
	getAddonCatalogLink,
	getAddonsQueryVariables,
	hasGroupMembership,
	Queries,
	Types,
	useMembershipContext,
	useUserContext
} from 'common';
import { HorizontalList } from 'components';

import useStyles from './MediathekAddons.styles';

type Props = {
	className?: string;
};

const MediathekAddons = (props: Props) => {
	const { className } = props;
	const userCtx = useUserContext();
	const { user } = userCtx;
	const { userMembership, isMember } = useMembershipContext();
	const { name: companyName } = user?.company || {};
	const [offset, setOffset] = useState(0);
	const classes = useStyles();
	const { data, loading: loading } = Queries.useGetAddOnCoursesQuery({
		variables: getAddonsQueryVariables(),
		skip: !companyName
	});

	const addonContents = data?.courses.data || [];

	if (!user || (!isMember && !hasGroupMembership(userMembership)) || !companyName) {
		return null;
	}

	const pageSize = 3;

	return (
		<Box className={classnames(classes.container, className)}>
			<HorizontalList
				items={addonContents}
				renderItem={(addonContent) => {
					const progressLink = `/${addonContent.brand === Types.ProductBrand.Syrot ? 'syrot' : 'ondemand-kurs'}/${addonContent.contentId}`;
					const currentSchedule = addonContent.courseProgress?.lastWatchedVideo;
					const courseLink = getAddonCatalogLink(
						addonContent.contentId,
						currentSchedule?.contentId
					);
					const doesSyrotHaveTests = Boolean(
						addonContent.courseProgress?.lastWatchedVideo?.questions &&
							addonContent.courseProgress?.lastWatchedVideo?.questions.length > 0
					);
					return (
						<AddonCard
							completedPercent={addonContent.courseProgress?.completedPercent}
							lastWatchedVideo={addonContent.courseProgress?.lastWatchedVideo}
							fullContent={addonContent}
							doesSyrotHaveTests={doesSyrotHaveTests}
							courseLink={courseLink}
							progressLink={progressLink}
							className={classes.addonCard}
							isCompact
						/>
					);
				}}
				numberOfItemsInRow={pageSize}
				totalCount={addonContents.length}
				offset={offset}
				onOffsetChange={setOffset}
				classes={{
					horizontalListContainer: classes.horizontalListContainer,
					item: classes.addonCardListItem
				}}
				renderSkeletonItem={() => (
					<AddonCardSkeleton className={classes.addonCard} isCompact />
				)}
				isSkeletonVisible={loading}
			/>
		</Box>
	);
};

export default MediathekAddons;
