import { Box, Container, useTheme } from '@mui/material';
import {
	booleanFilter,
	QueryParamsContext,
	Types,
	useRouter,
	useUserContext,
	useIsDesktop,
	getCountryCode,
	isFeatureEnabled
} from 'common';
import { flatMap, groupBy, mapValues, omit } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
	SelectorTopic,
	TopicsSelector
} from '../../components/TopicsHeader/TopicsSelector/TopicSelector';
import {
	useCurrentUserSpecialityQuery,
	useMediathekCoursesQuery,
	useTopicSortOrderQuery
} from '../../graphql/catalog/queries';
import { CoursesTopicTable } from './CoursesSection/components/CoursesTopicTable';
import { CoursesSection } from './CoursesSection/CoursesSection';
import { getFetchCoursesVariables } from './CoursesSection/CoursesSection.utils';
import { LecturesSection } from './LecturesSection';
import { MediathekHeader } from './MediathekHeader/MediathekHeader';
import {
	BrandContentsList,
	ContinueWatchingList,
	ParterForumContentsList,
	RecommendedContentsList,
	Webups
} from './MediathekHome.components';
import useStyles from './MediathekHome.styles';
import { MembershipBenefitsBannerBlue } from './MembershipBenefits/MembershipBenefitsBanner';
import { Newsletter } from './Newsletter/Newsletter';
import { OnDemandCoursesSection } from './CoursesSection/OnDemandCoursesSection';
import { useIsOnDemandAccreditationEnabled } from '../../hooks/useIsOnDemandAccreditationEnabled';

interface Props {
	initialTopicCode: string;
}

export const MediathekHomePage = ({ initialTopicCode }: Props) => {
	const classes = useStyles();
	const { isLoggedIn } = useUserContext();
	const { setQuery, query } = useRouter<{ topic: string | undefined }>();
	const { query: queryState } = useContext(QueryParamsContext);
	const intl = useIntl();
	const { isMobile } = useTheme();
	const isDesktop = useIsDesktop({ defaultMatches: !isMobile });
	const countryCode = getCountryCode(intl.locale);

	const isTopicSelectorEnabled = isFeatureEnabled('topicSelector', countryCode);
	const isMembershipEnabled = isFeatureEnabled('memberships', countryCode);

	const selectedTopicCode = useMemo(
		() => query.topic || (queryState.topic as string | undefined) || initialTopicCode,
		[query.topic, initialTopicCode, queryState.topic]
	);

	const { data: orderedTopicsIds } = useTopicSortOrderQuery({
		skip: !isLoggedIn
	});

	useCurrentUserSpecialityQuery({
		onCompleted: (data) => {
			const specialityCode = data.topicDocuments.data[0]?.code;
			if (!selectedTopicCode && specialityCode !== selectedTopicCode) {
				setQuery({
					topic: specialityCode
				});
			}
		},
		skip: !isLoggedIn || !selectedTopicCode
	});

	const { data: coursesData } = useMediathekCoursesQuery({
		variables: getFetchCoursesVariables(countryCode)
	});

	// Topic codes sorted by the amount of courses each topic have
	const sortedByCoursesTopicCodes = useMemo(() => {
		const flattened = flatMap(coursesData?.contentDocuments.data, (i) =>
			i.product?.topics.map((topic) => ({ ...i, topic }))
		);
		const grouped = groupBy(flattened, 'topic.code');
		const res = mapValues(grouped, (group) => group.map((item) => omit(item, 'topic')));
		return Object.entries(res).sort((a, b) => {
			return a[1].length > b[1].length ? -1 : 1;
		});
	}, [coursesData?.contentDocuments.data]);

	// displays topic button on each topic that have courses
	const topicsMapper = useCallback(
		(topics: Array<SelectorTopic>) => {
			const ordered = isLoggedIn
				? orderedTopicsIds?.topicDocuments.data
						.map(({ id }) => topics.find((t) => t.id === id))
						.filter(booleanFilter)
				: topics;

			const withCourses = sortedByCoursesTopicCodes
				.map((code) => topics.find((t) => t.code === code[0]))
				.filter(booleanFilter);

			const orderedWithDisplayButton =
				ordered?.map((o) => {
					if (withCourses.find((withCourse) => withCourse.id === o.id)) {
						return {
							...o,
							displayButton: true
						};
					}
					return { ...o, displayButton: false };
				}) ?? [];

			if (!isDesktop) {
				let displayButtonCount = 0;
				const res: Array<SelectorTopic> = [];
				orderedWithDisplayButton.forEach((topic) => {
					if (topic.displayButton && displayButtonCount < 4) {
						displayButtonCount++;
						res.push(topic);
					}
					res.push({ ...topic, displayButton: false });
				});
				return res;
			}
			return orderedWithDisplayButton;
		},
		[sortedByCoursesTopicCodes, isLoggedIn, isDesktop]
	);

	const onDemandAccreditationEnabled = useIsOnDemandAccreditationEnabled();
	const isPartnerForumEnabled = isFeatureEnabled('partnerForum', countryCode);

	return (
		<Box className={classes.container}>
			<Container maxWidth="xl" disableGutters>
				<MediathekHeader />
				{isTopicSelectorEnabled && (
					<Box className={classes.topicsContainer}>
						<TopicsSelector
							displayTopicButtons
							displayAllTopic={false}
							popoverPosition="end"
							useQueryState={false}
							displayAbbreviation
							mapTopics={topicsMapper}
						/>
					</Box>
				)}
				<Box marginX="12px">
					<CoursesTopicTable
						selectedTopicCode={selectedTopicCode}
						sortedByCoursesTopicCodes={sortedByCoursesTopicCodes}
					/>
					<CoursesSection
						filterSelectedTopicCode
						selectedTopicCode={selectedTopicCode}
						title={intl.formatMessage({ id: 'media-library.home.book-courses' })}
					/>
					{onDemandAccreditationEnabled && (
						<OnDemandCoursesSection
							selectedTopicCode={selectedTopicCode}
							title={intl.formatMessage({ id: 'catalog.on-demand.landing.title' })}
						/>
					)}
				</Box>
				{isMembershipEnabled && (
					<RecommendedContentsList selectedTopicCode={selectedTopicCode} />
				)}
				<Webups selectedTopicCode={selectedTopicCode} />
				{isMembershipEnabled && <MembershipBenefitsBannerBlue />}
				{isMembershipEnabled && <ContinueWatchingList />}
				<BrandContentsList
					brand={Types.ProductBrand.Summedup}
					topicCode={selectedTopicCode}
				/>
				<BrandContentsList brand={Types.ProductBrand.Skill} topicCode={selectedTopicCode} />
				{isPartnerForumEnabled && (
					<ParterForumContentsList selectedTopicCode={selectedTopicCode} />
				)}
				<LecturesSection selectedTopicCode={selectedTopicCode} />
				<Newsletter />
			</Container>
		</Box>
	);
};
