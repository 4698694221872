import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ExclusiveForMembersLabel } from '../../../components/ExclusiveForMembersLabel';
import { MediathekSectionHeader } from '../MediathekSectionHeader';
import { CATALOG_URLS, getCountryCode, Types, useMembershipContext } from 'common';
import { CoursesTable, type CoursesTableProps } from './components/CoursesTable';
import { useIntl } from 'react-intl';
import { useOnDemandCoursesQuery, useTopicSortOrderQuery } from '../../../graphql/catalog/queries';
import { getCustomTopicSortOrder, prepareCoursesData } from './CoursesSection.utils';
import { getFetchOnDemandCoursesVariables } from '../../OnDemand';

type CoursesSectionProps = {
	selectedTopicCode: string;
	title: string;
};

const useStyles = makeStyles(
	(theme) => ({
		label: {
			margin: theme.spacing(0, 0, 3, 3)
		}
	}),
	{ name: 'OnDemandCoursesSection' }
);

/**
 * Component for displaying a section with on-demand courses on the Home Page.
 *
 * @param {string} props.selectedTopicCode - The code of the selected topic.
 * @param {string} props.title - The title of the section.
 */
export const OnDemandCoursesSection = ({ selectedTopicCode, title }: CoursesSectionProps) => {
	const intl = useIntl();
	const countryCode = getCountryCode(intl.locale);
	const isCMED = countryCode === 'gb';

	const classes = useStyles();

	const { isMember } = useMembershipContext();

	const seeMoreUrl = isCMED ? null : CATALOG_URLS.onDemandLanding;

	const { data: topicsResponse } = useTopicSortOrderQuery({
		variables: getCustomTopicSortOrder(selectedTopicCode)
	});

	const { data: coursesData } = useOnDemandCoursesQuery({
		variables: getFetchOnDemandCoursesVariables(countryCode)
	});

	const courses: CoursesTableProps['courses'] = prepareCoursesData({
		topicData: topicsResponse?.topicDocuments.data,
		coursesData: coursesData?.courses,
		intl,
		isOnDemandTable: true,
		isMember
	});

	if (!courses.length) {
		return null;
	}

	return (
		<>
			<MediathekSectionHeader text={title} seeMoreUrl={seeMoreUrl} />
			<ExclusiveForMembersLabel className={classes.label} />
			<CoursesTable
				courses={courses}
				variant={Types.ProductBrand.Ondemand}
				hideMoreCoursesLink={isCMED === true}
			/>
		</>
	);
};
