import { gql } from '@apollo/client';

export const SourceFields = gql`
	fragment SourceFields on Source {
		thumbnail
		duration
		file {
			downloadUrl
			name
			thumbnailUrl
		}
		url
	}
`;

export const LabelValueFields = gql`
	fragment LabelValueFields on LabelValueR {
		value
		label {
			translations {
				locale
				value
			}
		}
	}
`;

const TranslatedTextFields = gql`
	fragment TranslatedTextFields on TranslatedText {
		locale
		value
	}
`;

export const UserAddressFields = gql`
	fragment UserAddressFields on AddressR {
		__typename
		apartmentNumber
		city
		country
		id
		postalCode
		street
		isPersonal
		fullName
		company
		additionalInformation
	}
`;

export const MembershipItemFields = gql`
	${TranslatedTextFields}
	fragment MembershipItemFields on MembershipItem {
		__typename
		amount
		amountUnit
		country
		featureType
		id
		name {
			...TranslatedTextFields
		}
		price
		membershipItemType
	}
`;

// TODO: Get rid of "membershipItems" field under "Membership"
// https://fomfsite.atlassian.net/browse/EM-1086
export const MembershipFields = gql`
	${MembershipItemFields}
	${LabelValueFields}
	fragment MembershipFields on Membership {
		__typename
		occupationType
		country
		currency
		cycle
		id
		name {
			value
			locale
		}
		orderPriority
		price
		recommended
		membershipType
		subtitle {
			locale
			value
		}
		membershipItems {
			...MembershipItemFields
		}
		validMarketingOptions {
			...LabelValueFields
		}
		feeItem {
			...MembershipItemFields
		}
	}
`;

export const UserMembershipItemFields = gql`
	${MembershipItemFields}
	fragment UserMembershipItemFields on UserMembershipItem {
		amountUnit
		remainingAmount
		usedAmount
		initialAmount
		membershipItem {
			...MembershipItemFields
		}
	}
`;

export const UserMembershipFields = gql`
	${MembershipFields}
	${UserMembershipItemFields}
	fragment UserMembershipFields on UserMembership {
		__typename
		id
		userId
		state
		items {
			...UserMembershipItemFields
		}
		cycle
		expiresAt
		membership {
			...MembershipFields
		}
		renewalStatus
		membershipRenewalType
		addedToGroupMembershipDateTime
		initialStartedAt
		startedAt
		legalPaymentReminders {
			id
		}
	}
`;

export const AcademicDegreeFields = gql`
	fragment AcademicDegreeFields on AcademicDegreeR {
		code
		description {
			locale
			value
			isSuffix
		}
		gender
		id
	}
`;

export const CreditPoint = gql`
	fragment CreditPoint on CreditPointR {
		amount
		unit
		mainAccreditation
	}
`;

export const CompanyFields = gql`
	fragment CompanyFields on Company {
		id
		name
		country
		logoUrl
		companyPage {
			slug
			publishStatus
		}
	}
`;

export const DepartmentFields = gql`
	fragment DepartmentFields on Department {
		id
		translatedName
		logoUrl
	}
`;

export const UserFields = gql`
	${UserAddressFields}
	${AcademicDegreeFields}
	${CreditPoint}
	${CompanyFields}
	fragment UserFields on UserR {
		__typename
		academicDegree {
			...AcademicDegreeFields
		}
		id
		accountType
		ubAccountCode
		email
		firstName
		gender
		lastName
		country
		healthProfessionalConfirmation
		mobile
		medicalIdentificationNumber
		newsletterSubscription
		fieldOfActivity
		occupationType
		lastLogin
		roles
		totalPointCertified {
			...CreditPoint
		}
		totalMembershipPointsEffectedCertified {
			...CreditPoint
		}
		specialities {
			id
			code
			description {
				translations {
					locale
					value
				}
			}
		}
		addresses {
			...UserAddressFields
		}
		isActivated
		hasValidActivationToken
		company {
			...CompanyFields
		}
		createdAt
		dataSharingConsent
		privacyPolicyConfirmation
		isCompleteOnboardingRequired
	}
`;

export const ParticipantTypeFields = gql`
	${LabelValueFields}
	${TranslatedTextFields}
	fragment ParticipantTypeFields on ParticipantTypeR {
		code
		id
		memberIdRequired
		name {
			...TranslatedTextFields
		}
		occupationType
		country {
			...LabelValueFields
		}
	}
`;

export const ParticipantTypePriceFields = gql`
	${ParticipantTypeFields}
	fragment ParticipantTypePriceFields on ParticipantTypePriceR {
		currentPrice
		earlyBirdPrice
		latePrice
		normalPrice
		memberPrice
		participantType {
			...ParticipantTypeFields
		}
	}
`;

export const EventBookableItemFields = gql`
	${ParticipantTypePriceFields}
	${CreditPoint}
	fragment EventBookableItemFields on EventBookableItemR {
		endDateTime
		eventBookableItemId
		name
		participationType
		startDateTime
		ticketType
		capacity
		bookedCount
		creditPoints {
			...CreditPoint
		}
		participantTypePrices {
			...ParticipantTypePriceFields
		}
	}
`;

export const ProductCategoryRFields = gql`
	${TranslatedTextFields}
	fragment ProductCategoryRFields on ProductCategoryR {
		code
		description {
			...TranslatedTextFields
		}
		id
	}
`;

export const BookingEventFields = gql`
	${EventBookableItemFields}
	${ParticipantTypeFields}
	${LabelValueFields}
	${ProductCategoryRFields}
	fragment BookingEventFields on BookingEventR {
		__typename
		currency
		id
		name
		locale
		publishedDomains
		allowedParticipantTypes {
			...ParticipantTypeFields
		}
		items {
			...EventBookableItemFields
		}
		productCategory {
			...ProductCategoryRFields
		}
		validMarketingOptions {
			...LabelValueFields
		}
		arePromoCodesAvailable
		city {
			...LabelValueFields
		}
		country {
			...LabelValueFields
		}
		eventFormat
		startDateTime
		endDateTime
		category
		taxPercentage
		taxType
		printedCourseBookFunctionId
		printedCourseBookPrice
		availableForMembers
		content {
			contentId
			externalId
		}
	}
`;

export const PromoCodeForOrderFields = gql`
	fragment PromoCodeForOrderFields on PromoCodeForOrderR {
		code
		name
		promoAmount
		promoCodeType
	}
`;

export const PromoCodeForMembershipOrder = gql`
	fragment PromoCodeForMembershipOrder on PromoCodeForMembershipOrder {
		code
		name
		promoAmount
		promoCodeType
	}
`;

const PriceListItemFields = gql`
	${ParticipantTypeFields}
	fragment PriceListItemFields on PriceListItemR {
		discountType
		earlyBirdPrice
		id
		latePrice
		normalPrice
		memberPrice
		participationType
		ticketType
		participantType {
			...ParticipantTypeFields
		}
	}
`;

export const PriceListFields = gql`
	${LabelValueFields}
	${PriceListItemFields}
	${ProductCategoryRFields}
	fragment PriceListFields on PriceListR {
		earlyBirdExpirationWeeks
		id
		isDefault
		name
		normalPriceExpirationDays
		validFrom
		validUntil
		productCategory {
			...ProductCategoryRFields
		}
		items {
			...PriceListItemFields
		}
		country {
			...LabelValueFields
		}
		currency
		taxPercentage
		taxType
		printedCourseBookPrice
	}
`;

export const SpeakerFields = gql`
	fragment SpeakerFields on Speaker {
		id
		avatarSrcUrl
		description
		gender
		name
		title
	}
`;

export const ContentCreditFields = gql`
	fragment ContentCreditFields on ContentCredit {
		amount
		unit
		isApproved
		accreditationInstitute
		participationType
	}
`;

const _TopicFields = gql`
	fragment _TopicFields on Topic {
		id
		code
		level
		subtopicPriority
		abbreviation
		description
		specialityIconUrl
		iconPrimaryColor
	}
`;

export const TopicFields = gql`
	${_TopicFields}
	fragment TopicFields on Topic {
		..._TopicFields
		parents {
			code
		}
	}
`;

export const _ProductDocumentFields = gql`
	fragment _ProductDocumentFields on ProductDocument {
		name
		code
		id
		country
		specialityIconUrl
		iconPrimaryColor
		brand
	}
`;

export const _ProductFields = gql`
	fragment _ProductFields on Product {
		name
		description
		specialityIconUrl
		code
		id
		country
		isScientificLeaderEnabled
		descriptionTitle
		targetGroupIds
		benefitIds
		iconPrimaryColor
	}
`;

export const BenefitFields = gql`
	fragment BenefitFields on Benefit {
		id
		title
		footNote
		iconUrl
		isFootNoteEnabled
		usage
	}
`;

export const TargetGroupFields = gql`
	fragment TargetGroupFields on TargetGroup {
		id
		title
	}
`;

export const ProgramScheduleFields = gql`
	${SpeakerFields}
	fragment ProgramScheduleFields on ProgramSchedule {
		id
		title
		startDateTime
		endDateTime
		externalId
		sponsorships {
			sponsorCompany {
				logoUrl
				name
			}
		}
		speakers {
			...SpeakerFields
		}
	}
`;

export const SponsorshipFields = gql`
	${ProgramScheduleFields}
	${_ProductFields}
	${SourceFields}
	${SpeakerFields}
	fragment SponsorshipFields on Sponsorship {
		contentId
		description
		sponsorCompany {
			id
			name
			logoUrl
			websites
			companyPage {
				slug
				publishStatus
			}
		}
		sponsorDepartments {
			companyPage {
				publishStatus
			}
			name
			logoUrl
			id
		}
		id
		sponsorCompanyId
		sponsorshipAmount
		sponsorshipType
		virtualExhibition
		symposiumIds
		entireEventSponsor
		participantRestriction
		symposiums {
			...ProgramScheduleFields
		}
		content {
			id
			brand
			startDateTime
			endDateTime
			contentId
			contentType
			locale
			title
			city
			headerUrl
			willBeBroadcasted
			rating
			product {
				..._ProductFields
			}
			source {
				...SourceFields
			}
			speakers {
				...SpeakerFields
			}
			externalId
		}
	}
`;

export const PartnershipFields = gql`
	fragment PartnershipFields on Partnership {
		id
		partnershipType
		partnerCompany {
			name
			logoUrl
		}
	}
`;

const SeoSettingsFields = gql`
	fragment SeoSettingsFields on SeoSettings {
		description
		image
		indexSetting
		title
	}
`;

/**
 * The Content Fields will load all the relations considering schedules and parent
 * while the ContentSearchData will not load them and is being used for the home and
 * search pages.
 */
// TODO rename this to something else as this is not a private fragment
export const _ContentFields = gql`
	${ContentCreditFields}
	${SpeakerFields}
	${TopicFields}
	${_ProductFields}
	${BenefitFields}
	${TargetGroupFields}
	${SponsorshipFields}
	${PartnershipFields}
	${SeoSettingsFields}
	${SourceFields}
	${CompanyFields}
	fragment _ContentFields on Content {
		id
		slug
		brand
		endDateTime
		externalId
		contentId
		contentType
		startDateTime
		willBeBroadcasted
		willBeOnDemand
		isFree
		isCommercial
		isAvailableOnHomepage
		isAvailableOnMediathek
		isAvailableWithoutEfn
		participationOption
		capacityStatus
		locale
		description
		descriptionTitle
		title
		city
		order
		headerUrl
		isSponsored
		rating
		contentUnit {
			id
			title
			order
		}
		provider {
			...CompanyFields
		}
		contentProgress {
			completed
			contentId
			time
			trackedDateTime
		}
		contentUnits {
			id
			title
			order
		}
		membershipSettings {
			availableForMembers
		}
		detailsPageSettings {
			eventBookable
			redirectThroughExhibition
		}
		agendaSettings {
			defaultDescription
			showAgenda
			showProgramOverview
			showSpeakers
		}
		accreditationSettings {
			description
			showScheme
			showSection
		}
		seoSettings {
			...SeoSettingsFields
		}
		sponsorships {
			...SponsorshipFields
		}
		partnerships {
			...PartnershipFields
		}
		benefits {
			...BenefitFields
		}
		targetGroups {
			...TargetGroupFields
		}
		product {
			..._ProductFields
			benefits {
				...BenefitFields
			}
			targetGroups {
				...TargetGroupFields
			}
		}
		credits {
			...ContentCreditFields
		}
		speakers {
			...SpeakerFields
		}
		topics {
			...TopicFields
		}
		parent {
			locale
			isFree
			sponsorships {
				sponsorCompany {
					id
					logoUrl
					name
					companyPage {
						slug
						publishStatus
					}
				}
				sponsorDepartments {
					name
					logoUrl
					id
				}
			}
		}
		replacementDate
		replacementTitle
		source {
			...SourceFields
		}
	}
`;

export const FileFields = gql`
	fragment FileFields on File {
		downloadUrl
		id
		mimetype
		name
		shortUrl
		size
		uploadUrl
		url
		permissions {
			id
			permissionType
			scope
		}
	}
`;

export const AttachmentFields = gql`
	${FileFields}
	fragment AttachmentFields on Attachment {
		attachmentType
		contentId
		fileId
		groupId
		id
		file {
			...FileFields
		}
	}
`;

export const SimpleContent = gql`
	fragment SimpleContent on Content {
		id
		brand
		title
		externalId
		contentId
		subtitle
		provider {
			id
		}
	}
`;

export const SimpleCourse = gql`
	fragment SimpleCourse on Course {
		id
		brand
		title
		externalId
		contentId
		subtitle
	}
`;

export const ReportFields = gql`
	fragment ReportFields on Report {
		file {
			id
			downloadUrl
			url
			name
		}
		reportDate
		reportStatus
	}
`;

export const AddonCourseFields = gql`
	${SimpleCourse}
	${ReportFields}
	fragment AddonCourseFields on Course {
		__typename
		...SimpleCourse
		certificate {
			...ReportFields
		}
		modules {
			id
			title
			order
		}
		topics {
			id
			code
			description
		}
		courseProgress {
			lastWatchedVideo {
				contentId
				questions {
					id
				}
				source {
					duration #
					thumbnail
				}
				contentProgress {
					time
					completed
				}
				endDateTime
				replacementDate
				contentType
				product {
					specialityIconUrl
				}
				title
				contentUnit {
					title
				}
			}
			completedPercent
		}
	}
`;

export const ContentWithProgramSchedulesFields = gql`
	${_ContentFields}
	${SpeakerFields}
	${AttachmentFields}
	${CompanyFields}
	${DepartmentFields}
	${ReportFields}
	fragment ContentWithProgramSchedulesFields on Content {
		__typename
		..._ContentFields
		location
		headerUrl
		dominantColor
		certificate {
			...ReportFields
		}
		schedule {
			..._ContentFields
			contentUnit {
				id
				title
				order
			}
		}
		provider {
			companyPage {
				slug
			}
		}
		parent {
			..._ContentFields
		}
		speakers {
			...SpeakerFields
		}
		scientificLeaders {
			...SpeakerFields
		}
		programSchedules {
			id
			title
			startDateTime
			endDateTime
			level
			scheduleType
			speakers {
				...SpeakerFields
			}
			sponsorships {
				sponsorCompany {
					...CompanyFields
				}
			}
		}
		attachments {
			...AttachmentFields
		}
		sponsorships {
			sponsorDepartments {
				...DepartmentFields
			}
			sponsorCompany {
				id
			}
		}
		eventCost
		specialities
		subtitle
	}
`;

export const ContentDocumentSearchFields = gql`
	${ContentCreditFields}
	${SpeakerFields}
	${TopicFields}
	${_ProductDocumentFields}
	${SourceFields}
	fragment ContentDocumentSearchFields on ContentDocument {
		__typename
		id
		brand
		endDateTime
		contentId
		contentType
		startDateTime
		scheduleItemsCount
		specialities
		headerUrl
		dominantColor
		externalId
		willBeBroadcasted
		isFree
		isAvailableOnHomepage
		capacityStatus
		participationOption
		locale
		city
		subtitle
		title
		description
		slug
		rating
		sponsorships {
			sponsorDepartments {
				name
				id
				logoUrl
			}
		}
		providers {
			name
			companyPage {
				slug
				publishStatus
			}
			id
			logoUrl
		}
		product {
			..._ProductDocumentFields
		}
		credits {
			...ContentCreditFields
		}
		speakers {
			...SpeakerFields
		}
		topics {
			...TopicFields
		}
		parent {
			id
			contentType
			isFree
			contentId
			title
			city
			locale
			brand
			startDateTime
			providers {
				name
				id
				logoUrl
			}
		}
		source {
			...SourceFields
		}
	}
`;

export const AggregationFields = gql`
	fragment AggregationFields on SearchResultAggregation {
		buckets {
			key
			value
		}
		name
	}
`;

export const BookingFields = gql`
	${LabelValueFields}
	${ParticipantTypeFields}
	${ProductCategoryRFields}
	fragment BookingFields on BookingR {
		bookingDate
		bookingStatus
		currency
		membershipCreditEffective
		event {
			productCategory {
				...ProductCategoryRFields
			}
			category
			city {
				...LabelValueFields
			}
			country {
				...LabelValueFields
			}
			description
			endDateTime
			id
			ubEventId
			locale
			publishedDomains
			livestream
			name
			onSite
			eventFormat
			specialities {
				...LabelValueFields
			}
			startDateTime
			availableForMembers
			items {
				functionId
				name
				startDateTime
				endDateTime
				ticketType
			}
		}
		totalPrice
		id
		userId
		participantType {
			...ParticipantTypeFields
		}
		bookingItems {
			eventBookableItemFunctionId
			bookingItemType
			bookingItemStatus
		}
		invoiceFile {
			name
			downloadUrl
		}
		ubOrderId
		certificates {
			certificateFile {
				name
				downloadUrl
			}
			participationType
		}
		invoiceLastUpdated
		bookingUpdatedDate
	}
`;

export const SlotCreditFields = gql`
	fragment SlotCreditFields on SlotCredit {
		amount
		certified
		unit
		mainAccreditation
	}
`;

export const EventParticipantsSummaryFields = gql`
	${SlotCreditFields}
	fragment EventParticipantsSummaryFields on EventParticipantsSummaryR {
		id
		attendanceOverviews {
			title
		}
		attendanceRecords {
			id
			attendanceSlotId
			attendanceSlotTitle
			eventId
			participantId
			recordDate
			recordType
			attendanceSlotType
		}
		certificateSendingError
		certificateStatus
		certificateUrl
		creditTransferStatus
		eventId
		grantedPoints {
			...SlotCreditFields
		}
		maxPoints {
			...SlotCreditFields
		}
		participantEfnNumber
		participantFullName
		participantId
		isBookingPaid
		certificateSentAt
		certificateSentByUser {
			id
			email
			firstName
			lastName
		}
		certificates {
			certificateFile {
				name
				downloadUrl
			}
			participationType
		}
	}
`;

export const UserPaymentMethodFields = gql`
	fragment UserPaymentMethodFields on UserPaymentMethodR {
		brand
		default
		last4
		reference
		country
		type
	}
`;

/**
 * Was used in admin-catalog for the "contents" query.
 *
 * TODO: arsinclair: need to decouple Partnerforum from it and remove the query.
 *
 * @deprecated
 */
export const ContentFields = gql`
	${ContentCreditFields}
	${SpeakerFields}
	${TopicFields}
	${_ProductFields}
	${SponsorshipFields}
	${BenefitFields}
	${SeoSettingsFields}
	${AttachmentFields}
	${CompanyFields}
	${SourceFields}
	fragment ContentFields on Content {
		__typename
		id
		brand
		endDateTime
		contentId
		contentType
		startDateTime
		description
		descriptionTitle
		isScientificLeaderEnabled
		specialities
		externalId
		willBeBroadcasted
		isFree
		isAvailableOnHomepage
		capacityStatus
		participationOption
		locale
		isAvailableWithoutEfn
		validDate
		isFree
		isOnDemand
		isOnSite
		willBeOnDemand
		subtitle
		title
		city
		partnershipIds
		sponsorshipIds
		publishedCountries
		targetGroupIds
		slug
		isCommercial
		benefitIds
		attachmentIds
		topicIds
		keywords
		provider {
			...CompanyFields
		}
		source {
			...SourceFields
		}
		detailsPageSettings {
			eventBookable
			redirectThroughExhibition
		}
		attachments {
			...AttachmentFields
		}
		sponsorships {
			...SponsorshipFields
		}
		accreditationSettings {
			description
			showScheme
			showSection
		}
		agendaSettings {
			defaultDescription
			showAgenda
			showProgramOverview
			showSpeakers
		}
		seoSettings {
			...SeoSettingsFields
		}
		product {
			..._ProductFields
			benefits {
				...BenefitFields
			}
		}
		credits {
			...ContentCreditFields
		}
		speakers {
			...SpeakerFields
		}
		scientificLeaders {
			...SpeakerFields
		}
		topics {
			...TopicFields
		}
		replacementDate
		replacementTitle
	}
`;

export const ProductFields = gql`
	${BenefitFields}
	${TargetGroupFields}
	${SeoSettingsFields}
	fragment ProductFields on Product {
		accreditation
		brand
		code
		country
		contentType
		description
		descriptionTitle
		isScientificLeaderEnabled
		id
		isEnabled
		isExternal
		liveStreamInfo
		specialityIconUrl
		iconPrimaryColor
		name
		seoSettings {
			...SeoSettingsFields
		}
		targetGroupIds
		targetGroups {
			...TargetGroupFields
		}
		benefitIds
		benefits {
			...BenefitFields
		}
	}
`;

export const DeletedResultFields = gql`
	fragment DeletedResultFields on DeletedResult {
		deleted
		id
	}
`;

export const MediathekVideoFields = gql`
	fragment MediathekVideoFields on ContentDocument {
		__typename
		id
		contentId
		contentType
		specialities
		title
		isFree
		startDateTime
		endDateTime
		product {
			name
			code
			id
			country
			specialityIconUrl
			iconPrimaryColor
		}
		credits {
			amount
			unit
		}
		source {
			duration
			thumbnail
		}
		speakers {
			id
			name
			title
		}
		topics {
			id
			code
			iconPrimaryColor
			parents {
				code
			}
		}
		parent {
			id
			contentId
			contentType
			isFree
		}
		rating
	}
`;

export const ContentForAnalyticsFields = gql`
	fragment ContentForAnalyticsFields on Content {
		id
		contentId
		externalId
		brand
		city
		contentType
		specialities
		product {
			code
			name
		}
		title
		partnerships {
			partnerCompany {
				ubAccountCode
				name
			}
		}
		startDateTime
		parent {
			id
			contentId
			externalId
			contentType
			brand
			city
			specialities
			product {
				code
				name
			}
			title
			partnerships {
				partnerCompany {
					ubAccountCode
					name
				}
			}
			startDateTime
		}
	}
`;

export const CourseForAnalyticsFields = gql`
	fragment CourseForAnalyticsFields on Course {
		id
		contentId
		brand
		city
		contentType
		specialities
		product {
			code
			name
		}
		title
		startDateTime
		parent {
			id
			contentId
			contentType
			brand
			city
			specialities
			product {
				code
				name
			}
			title
			startDateTime
		}
	}
`;

export const PartnerCompanySimplified = gql`
	fragment PartnerCompanySimplified on PartnerCompany {
		id
		name
		logoUrl
		description
		slug
	}
`;
