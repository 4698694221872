import React from 'react';
import { Chip } from '@mui/material';
import { Typography, Icon } from 'components';
import { formatters, getCountryCode } from 'common';
import { useIntl } from 'react-intl';
import useStyles from './ActiveFiltersChips.styles';
import FilterUtils from './Filters.utils';
import { dateFilterMenuOptions } from '../../utils/filters/dateFilter';
import {
	getContentTypeButtons,
	lectureSourceFilterButtons,
	CONTENT_TYPE_QUERY_PARAM_NAME,
	sortingProductMenuOptions
} from './Filters.types';

type Props = {
	onChange: () => void;
};
const ActiveFiltersChips = ({ onChange }: Props) => {
	const classes = useStyles();
	const { locale } = useIntl();
	const countryCode = getCountryCode(locale);
	const languageDropdownOptions = FilterUtils.getDomainLanguageOptions(countryCode);
	const { queryParams, topics, removeFilter, handleTopicChange, setAllTopics } =
		FilterUtils.useFilters(onChange);

	const activeFilters = Object.entries(queryParams).filter(
		([key, value]) => value && key !== 'topics'
	);

	const removeTopic = (index: number) => {
		const parentTopic = topics[index - 1];
		if (parentTopic) {
			handleTopicChange(parentTopic);
		} else {
			setAllTopics([]);
		}
	};

	const contentTypeButtons = getContentTypeButtons(locale);

	const handleValueTranslation = (key: string, value: string | null) => {
		if (key === CONTENT_TYPE_QUERY_PARAM_NAME) {
			return { localeId: contentTypeButtons.find(({ id }) => id === value)?.localeId };
		}
		if (key === 'lectureSource') {
			return {
				localeId: lectureSourceFilterButtons.find(({ id }) => id === value)?.localeId
			};
		}
		if (key === 'language') {
			return { localeId: languageDropdownOptions.find(({ id }) => id === value)?.localeId };
		}
		if (key === 'date') {
			return { localeId: dateFilterMenuOptions.find(({ id }) => id === value)?.localeId };
		}
		if (key === 'sortBy') {
			return { localeId: sortingProductMenuOptions.find(({ id }) => id === value)?.localeId };
		}
		return { title: value };
	};

	return (
		<div className={classes.chipContainer}>
			{topics.map((each, index) => (
				<Chip
					key={each.id}
					label={
						<Typography
							variant="textSmall"
							title={formatters.formatTranslation(each.description, {
								locale
							})}
							className={classes.chipTitle}
						/>
					}
					onDelete={() => {
						removeTopic(index);
					}}
					className={classes.chip}
					classes={{ deleteIcon: classes.chipDeleteIcon }}
					deleteIcon={<Icon icon="close" />}
				/>
			))}
			{activeFilters.map(([key, value]) => (
				<Chip
					key={key}
					label={
						<>
							<Typography
								localeId={key}
								variant="textSmall"
								className={classes.chipTitle}
							/>
							:&nbsp;
							<Typography
								variant="textSmall"
								className={classes.chipTitle}
								{...handleValueTranslation(key, value)}
							/>
						</>
					}
					onDelete={() => {
						removeFilter(key);
					}}
					className={classes.chip}
					classes={{ deleteIcon: classes.chipDeleteIcon }}
					deleteIcon={<Icon icon="close" />}
				/>
			))}
		</div>
	);
};

export default ActiveFiltersChips;
