import React, { memo, useMemo, useRef } from 'react';
import { Box, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { Button, DateFormat, Link, TitleTooltip, Typography, Whitespace } from 'components';
import {
	formatters,
	BrandIcon,
	ContentTransformers,
	FreeBadge,
	showFreeBadge,
	useMembershipContext,
	Fragments,
	Types,
	VideoRating,
	ColoredIcon,
	useIntersectionObserver,
	BOOKING_URLS,
	buildURLWithParams
} from 'common';
import { contentUtils } from '../../utils';
import useOnContentClick from '../../hooks/useOnContentClick';

import useStyles from './WebUpItem.styles';
import { WebupItemProps } from './WebUpItem.types';
import { catalogTracking } from '../../utils/segment';
import Dayjs from 'dayjs';

type CreditsProps = Pick<Fragments.ContentDocumentSearchFieldsFragment, 'credits'> & {
	locale: Locale;
	isFeatured: boolean;
};

export const WebUpCredits = ({ credits, locale, isFeatured }: CreditsProps) => {
	const classes = useStyles();
	const liveStreamCredits = credits.filter(
		(c) => c.participationType === Types.ParticipationType.Livestream
	);

	if (liveStreamCredits.length > 1) {
		const toDisplayCredit = liveStreamCredits[0];
		const displayedCreditText =
			toDisplayCredit && contentUtils.generateCreditString(toDisplayCredit, locale);
		const tooltipText = contentUtils.generateCreditsString(credits, locale);
		return (
			<Tooltip
				title={tooltipText}
				placement="top-start"
				classes={{ tooltip: classes.tooltip }}
			>
				<Typography color="secondary">
					{displayedCreditText} (+
					{liveStreamCredits.length - 1})
				</Typography>
			</Tooltip>
		);
	} else if (credits.length > 0) {
		const toDisplayCredit = liveStreamCredits[0] ?? credits[0];
		return (
			<Typography
				title={
					toDisplayCredit
						? contentUtils.generateCreditString(toDisplayCredit, locale)
						: ''
				}
				color={isFeatured ? 'offWhite' : 'secondary'}
			/>
		);
	}

	return null;
};

const WebUpItem = (props: WebupItemProps) => {
	const onProductClick = useOnContentClick();
	const { webup, index, isFeatured, selectedTopicCode } = props;
	const {
		isFree,
		parent,
		credits,
		brand,
		endDateTime,
		startDateTime,
		title,
		headerUrl,
		rating,
		product,
		topics
	} = webup;
	const { messages, locale } = useIntl();
	const classes = useStyles();
	const { isMember } = useMembershipContext();

	const containerRef = useRef<HTMLDivElement | null>(null);
	useIntersectionObserver(containerRef, {}, () => {
		catalogTracking.courseThumbnailViewed(webup.contentId, { locale, index });
	});

	const selectedTopic = topics.find((topic) => topic.code === selectedTopicCode);

	// try to use topic icon first before trying product icon
	const { specialityIconUrl: iconUrl, iconPrimaryColor: topicColor } =
		selectedTopic || product || {};

	const labelColor = isFeatured ? 'accent' : 'label';

	const displayBookWebupButton = useMemo(() => {
		const now = Dayjs();
		return now.isBefore(Dayjs(webup.startDateTime));
	}, [webup.startDateTime]);
	return (
		<Box
			className={classes.container}
			onClick={onProductClick(webup, { index })}
			data-testid="webup-item"
			style={isFeatured ? { backgroundColor: topicColor } : undefined}
			ref={containerRef}
		>
			<Box className={classes.imageContainer}>
				{isFeatured && iconUrl ? (
					<ColoredIcon
						className={classes.topicIcon}
						color="white"
						width="100%"
						height="100%"
						url={iconUrl}
					/>
				) : headerUrl ? (
					<img
						className={classes.image}
						height="100%"
						width="100%"
						alt={title || ''}
						src={headerUrl}
					/>
				) : (
					brand && (
						<Box className={classes.image}>
							<BrandIcon
								brand={brand}
								classes={{
									container: classes.brandContainer,
									brandName: classes.brandNameWebinar,
									image: classes.brandImageWebinar
								}}
							/>
						</Box>
					)
				)}
				{showFreeBadge({
					contentIsFree: isFree,
					parentContentIsFree: parent?.isFree,
					isMember
				}) && <FreeBadge classes={{ freeBadge: classes.freeBadge }} variant="white" />}
			</Box>
			<Box className={classes.content}>
				<div>
					{brand && (
						<Typography
							variant="textXSmall"
							title={formatters.formatEnum(brand, {
								messages,
								options: ContentTransformers.brand
							})}
							color={labelColor}
							className={classes.brand}
							fontFamily="Poppins"
						/>
					)}
					<TitleTooltip title={title}>
						<Typography
							title={title}
							className={classes.name}
							color={isFeatured ? 'offWhite' : 'primary'}
							variant="textMedium"
							fontFamily="Poppins"
						/>
					</TitleTooltip>
					<VideoRating rating={rating} />
					<Box className={classes.badgeContainer}>
						<Typography
							title={formatters.formatDate(startDateTime, DateFormat.monthAndWeekday)}
							variant="body1"
							color={labelColor}
						/>
					</Box>
				</div>
				<Box className={classes.footer}>
					<Box display="flex">
						<Typography
							title={formatters.formatDate(startDateTime, DateFormat.time)}
							variant="body1"
							color={labelColor}
						/>
						<Whitespace />
						<Typography
							title={`- ${formatters.formatDate(endDateTime, DateFormat.time)}`}
							variant="body1"
							color={labelColor}
						/>
					</Box>
					{credits.length > 0 && contentUtils.isInTheFuture(webup) && (
						<WebUpCredits credits={credits} locale={locale} isFeatured={isFeatured} />
					)}
				</Box>
				{displayBookWebupButton && (
					<Button
						component={Link}
						href={buildURLWithParams(BOOKING_URLS.event, [webup.externalId ?? ''])}
						localeId="catalog.webup-item.button"
						uppercase={false}
					/>
				)}
			</Box>
		</Box>
	);
};

export default memo(WebUpItem);
