import makeStyles from '@mui/styles/makeStyles';
import { logoWidth } from 'components';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			[theme.breakpoints.down('md')]: {
				display: 'none'
			}
		},
		container: {
			padding: 0,
			maxWidth: ({ maxWidth }: { maxWidth?: number }) => (maxWidth ? maxWidth : '1440px')
		},
		topContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: 80
		},
		primaryButton: {
			marginRight: theme.spacing(3),
			backgroundColor: theme.palette.secondary[300],
			borderRadius: 6
		},
		link: {
			display: 'flex',
			width: '100%',
			height: '100%',
			alignItems: 'center',

			'& img': {
				maxWidth: '100%'
			}
		},
		logoWrapperContainer: {
			display: 'flex',
			alignItems: 'center',
			flex: 1,
			marginInlineEnd: 14
		},
		logo: {
			width: logoWidth,
			height: '100%',
			display: 'flex',
			marginRight: theme.spacing(4)
		},
		navbarContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		avatar: {
			width: 40,
			height: 40,
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary[700],
			...theme.typography.body2
		},
		userInfoContainer: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: theme.spacing(1.5)
		},
		iconsContainer: {
			display: 'flex',
			gap: theme.spacing(1.5)
		},
		iconsContainerMd: {
			[theme.breakpoints.up('lg')]: {
				display: 'none'
			}
		},
		iconsContainerLg: {
			display: 'none',
			marginLeft: theme.spacing(2),
			[theme.breakpoints.up('lg')]: {
				display: 'flex'
			}
		},
		iconButton: {
			padding: theme.spacing(1)
		},
		icon: {
			width: '24px',
			height: '24px',
			'& svg': {
				color: theme.palette.primary.main
			}
		},
		topicsLink: {
			...theme.border({ width: 1, kind: 'left', color: theme.palette.body.light }),
			display: 'flex',
			padding: theme.spacing(0, 1.5, 0, 2),
			alignItems: 'center',
			fontFamily: 'Roboto',
			letterSpacing: 0.25,
			fontWeight: 700,
			fontSize: 14,
			transition: theme.transitions.create('color'),

			'&:hover': {
				color: theme.palette.secondary.main
			}
		},
		topicsIcon: {
			width: 20,
			height: 20,
			marginRight: theme.spacing(1),
			color: 'inherit'
		},
		searchBoxRoot: {
			marginRight: theme.spacing(4)
		},
		searchBoxInputBaseRoot: {
			maxWidth: 600,
			flexGrow: 1
		},
		localeSwitch: {
			flexShrink: 0
		}
	}),
	// to have more priority over Button primary color
	{ name: 'Header.desktop', index: 20 }
);

export default useStyles;
